import React from "react";
import "./index.css"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import sl from "./images/sl.png"
import { MsalProvider } from "@azure/msal-react";
import { PageLayout } from './ui';
import { msalInstance } from './msalInstance' // TODO - test converting to useMsal
import { AuthTestContent } from './Test/AuthTestContent'
import { ErrorTestContent } from './Test/ErrorTestContent'
import { Home } from './HomeContent'
import { YourAccountContent } from './YourAccount/YourAccountContent'
import { ChangeEmailAddress } from './YourAccount/ChangeEmailAddress'
import { ChangeEmailConfirm } from './YourAccount/ChangeEmailConfirm'
import { ShowBooking } from './YourAccount/ShowBooking'
import { ChangeBookingCard } from './YourAccount/ChangeBookingCard'
import { ShowInvoice } from "./YourAccount/ShowInvoice";
import { SavedCards } from './YourAccount/SavedCards'
import { EventContent } from "./EventBooking/EventContent";
import { EventOptionContent } from './EventBooking/EventOptionContent'
import { EventOptionBookingContent } from './EventBooking/EventOptionBookingContent'
import { Config } from './config'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { AppInsightsErrorBoundary } from "./ErrorBoundary";
import {ai} from './TelemetryService';
import TelemetryProvider from './TelemetryProvider';
import Admin from "./Admin/Admin";
import AdminEventList from "./Admin/AdminEventList";
import {AdminEventEdit, AdminEventNew} from "./Admin/AdminEventEdit";
import AdminEventOptionList from "./Admin/AdminEventOptionList";
import {AdminEventOptionEdit, AdminEventOptionNew} from "./Admin/AdminEventOptionEdit";
import AdminEventCouponList from "./Admin/AdminEventCouponList";
import {AdminEventCouponEdit, AdminEventCouponNew} from "./Admin/AdminEventCouponEdit";
import AdminBookingList from "./Admin/AdminBookingList";
import AdminBookingShow from "./Admin/AdminBookingShow";
import AdminBookingInvoiceShow from "./Admin/AdminBookingInvoiceShow";
import AdminCustomerList from "./Admin/AdminCustomerList";
import AdminMigrationsList from "./Admin/AdminMigrationsList";
import AdminCustomerShow from "./Admin/AdminCustomerShow";

export default function App() {
  const appInsightsKey = Config.current().appInsights.instrumentationKey;
  const stripeKey = Config.current().stripeKey;
  const stripePromise = loadStripe(stripeKey);

  

  return (
    <MsalProvider instance={msalInstance}>
      <Elements stripe={stripePromise}>
        <PageLayout>
          <Router>
            <TelemetryProvider instrumentationKey={appInsightsKey}>
              <AppInsightsErrorBoundary onError={() => <><h1>Error</h1><div>Sorry, we've just enountered an error!</div></>} appInsights={ai.appInsights}>
                <Switch>
                  <Route path="/" exact component={Home} />
                  <Route path="/my-account/change-email-address" exact component={ChangeEmailAddress} />
                  <Route path="/my-account/change-email-confirm" exact component={ChangeEmailConfirm} />
                  <Route path="/my-account/cards" component={SavedCards} />
                  <Route path="/my-account/bookings/:bookingid/invoices/:invoiceid" component={ShowInvoice} />
                  <Route path="/my-account/bookings/:bookingid/change-card" component={ChangeBookingCard} />
                  <Route path="/my-account/bookings/:bookingid" component={ShowBooking} />
                  <Route path="/my-account" exact component={YourAccountContent} />
                  <Route path="/_test/auth" component={AuthTestContent} />
                  <Route path="/_test/error" component={ErrorTestContent} />
                  <Route path="/events/:slug/:optionid/:bookingid" component={EventOptionBookingContent} />
                  <Route path="/events/:slug/:optionid" component={EventOptionContent} />
                  <Route path="/events/:slug" component={EventContent} />
                  <Route path="/admin/events/:slug/options/_new"  component={AdminEventOptionNew} />
                  <Route path="/admin/events/:slug/options/:optionId"  component={AdminEventOptionEdit} />
                  <Route path="/admin/events/:slug/options"  component={AdminEventOptionList} />
                  <Route path="/admin/events/:slug/coupons/_new"  component={AdminEventCouponNew} />
                  <Route path="/admin/events/:slug/coupons/:couponId"  component={AdminEventCouponEdit} />
                  <Route path="/admin/events/:slug/coupons"  component={AdminEventCouponList} />
                  <Route path="/admin/events/_new"  component={AdminEventNew} />
                  <Route path="/admin/events/:slug"  component={AdminEventEdit} />
                  <Route path="/admin/events" exact component={AdminEventList} />
                  <Route path="/admin/bookings/:bookingId/invoices/:invoiceId" component={AdminBookingInvoiceShow} />
                  <Route path="/admin/bookings/:bookingId" component={AdminBookingShow} />
                  <Route path="/admin/bookings" component={AdminBookingList} />
                  <Route path="/admin/customers/:customerId" component={AdminCustomerShow} />
                  <Route path="/admin/customers" component={AdminCustomerList} />
                  <Route path="/admin/migrations" exact component={AdminMigrationsList} />
                  <Route path="/admin" exact component={Admin} />
                </Switch>
              </AppInsightsErrorBoundary>
            </TelemetryProvider>
          </Router>
        </PageLayout>
      </Elements>
    </MsalProvider>
  );
}
