import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getApiAuthHeaderValue} from "../api";
import {Config} from "../config";
import {formatStringDateDMYHm} from "../utils";

class OldEmailAddresses {
	email: string = "";
	changedDate: string = "";
	changedDateFriendly: string = "";
}
class Customer {
	id: number = -1;
	name: string = "";
	email: string = "";
	stripeId: string = "";
	stripeCustomerUrl: string = "";
	userId: string = "";
	oldEmailAddresses: OldEmailAddresses[] = [];
}

export default function AdminCustomerShow() {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [error, setError] = useState<any>();
	const [customer, setCustomer] = useState<Customer>();

	const {customerId} = useParams() as {customerId: string};

	const {instance} = useMsal();
	const signIn = async (e: any) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};
	const loadData = useCallback(async () => {
		setIsLoading(true);
		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/eladmin/customers/${customerId}`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		const customer = response.data.customer as Customer;
		setCustomer(customer);
		setIsLoading(false);
	}, [customerId]);

	const isAuthenticated = useIsAuthenticated();
	useEffect(() => {
		if (isAuthenticated)
			loadData();
		return () => {};
	}, [isAuthenticated, loadData]);


	if (!isAuthenticated) {
		return <>
			<h3>Please sign in</h3>
			<div>
				To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
			</div>
		</>
	}
	if (error) {
		return <>
			<h1><a href="/admin">Admin</a> / <a href="/admin/customers">Customers</a> / {customerId} </h1>
			{error}
		</>
	}

	let customerInfo = <></>
	if (customer) {
		customerInfo = <>
			<div className="row">
				<div className="col-md-12">
					<table className="table">
						<tbody>
							<tr>
								<td className="table-label">Ref</td>
								<td>{customer.id}</td>
							</tr>
							<tr>
								<td className="table-label">Name</td>
								<td>{customer.name}</td>
							</tr>
							<tr>
								<td className="table-label">Email</td>
								<td>{customer.email}</td>
							</tr>
							<tr>
								<td className="table-label">StripId</td>
								<td><a href={customer.stripeCustomerUrl}>{customer.stripeId}</a></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<h2>Old Email Addresses</h2>
					{customer.oldEmailAddresses.length === 0
						? <>No old email addresses</>
						: <>
							<ul>
								{customer.oldEmailAddresses.map(o => <li>
									{o.email} - changed {o.changedDateFriendly} ({formatStringDateDMYHm(o.changedDate)})
								</li>)}
							</ul>
						</>}
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<a href={`/admin/bookings?customerId=${customer.id}`}>Show bookings...</a>
				</div>
			</div>
		</>
	}
	return <>
		<h1><a href="/admin">Admin</a> / <a href="/admin/customers">Customers</a> / {customerId} </h1>
		{isLoading ? "loading" : ""}
		{customerInfo}
	</>;
}
