import React, { Component } from "react";
import axios from 'axios';
import { Config } from '../config'
import Button from "react-bootstrap/Button";
import { getApiAuthHeaderValue } from '../api';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";


export const EventOptionContent = (props) => {
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const signIn = async (e) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};

	return (
		<>
			<AuthenticatedTemplate>
				<InjectedEventOptionContent msal={instance} isAuthenticated={isAuthenticated} {...props} />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<h3>Please sign in</h3>
				<div>
					To book for events, please <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
				</div>
			</UnauthenticatedTemplate>
		</>
	);
};
class InjectedEventOptionContent extends Component {
	state = {
		// Initial state.
		isLoading: false,
		eventOption: null,
		customer: null,
		error: null,
	};
	slug = this.props.match.params.slug;
	optionId = this.props.match.params.optionid;

	combine(part1, part2) {
		let result = part1;
		if (!result.endsWith("/")) {
			result = result + "/"
		}
		result = result + part2;
		return result;
	}
	startBooking = async (event) => {
		this.setState({ ...this.state, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.post(
				`${Config.current().apiConfig.webApi}/events/${this.slug}/${this.optionId}/bookings`, null, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			if (error?.code === "not-found-or-not-valid") {
				window.location = `${Config.current().portal.baseUrl}events/${this.slug}`;
			} else {
				this.setState({
					...this.state,
					isLoading: false,
					error: error?.response?.data?.error ?? error.message,
				});
			}
			return;
		}
		console.log(response);
		const bookingId = response.data.bookingId;
		window.location = this.combine(window.location.toString(), bookingId);

		console.log("Booking!!!");
		this.setState({
			...this.state,
			isLoading: false,
			isCompleted: false,
			error: null,
			validationError: null,
		});
	}

	render() {
		let header;
		let stepContent;
		let progress;
		if (this.state.error) {
			if (this.state.error === "NoPlacesLeft") {
				stepContent = (
					<div className="row">
						<div className="col-md-12">
							<div>Sorry, there are no longer any places left for this event</div>
						</div>
					</div>
				)

			} else {
				stepContent = (
					<div className="row">
						<div className="col-md-12">
							<div>Oops, sorry! An error occurred: {JSON.stringify(this.state.error)}</div>
						</div>
					</div>
				)
			}
		} else if (this.state.eventOption) {
			stepContent = (
				<>
					<div className="row">
						<div className="col-md-12">
							<p>To book for "{this.state.eventOption.eventName}" ({this.state.eventOption.title}) please click the Continue button
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<p><strong>NOTE:</strong> Any emails relating to the booking will be sent to <strong>{this.state.customer.email}</strong>. <a href={`${Config.current().portal.baseUrl}my-account/change-email`}>Click here</a> to change your email before booking.
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<p>Once you start the process a place will be reserved for you for 10 minutes - ensure you complete the process in this time to claim your place.</p><p> Alternatively, <a href="https://bookings.journeys-in-parenting.com/events">return to the Events page</a>.</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<Button className="btn-success" disabled={this.state.isLoading} onClick={this.startBooking}>Continue</Button>
						</div>
					</div>
				</>
			)
			header = (<h1><a href={`/events/${this.state.eventOption.eventSlug}`}>{this.state.eventOption.eventName}</a> / {this.state.eventOption.title} </h1>
			)
			progress = (
				<>
					<ul className="progress-indicator">
						<li className="active"> <span className="bubble"></span> Start booking </li>
						<li> <span className="bubble"></span> Terms and conditions </li>
						<li> <span className="bubble"></span> Payment details </li>
						<li> <span className="bubble"></span> Completed </li>
					</ul>
				</>)
		}
		return (
			<>
				<div className="App">
					{header}
					{this.state.isLoading ? <div>Processing, please wait...<span className="spinner" id="spinner"></span></div> : <></>}
					<AuthenticatedTemplate>
						{progress}
						{stepContent}
					</AuthenticatedTemplate>
					<UnauthenticatedTemplate>
						<h3>Please sign in</h3>
						<div>
							To book for this event, please <button type="button" className="link-button" onClick={(e) => this.signIn(e)}>sign in or create an account</button>
						</div>
					</UnauthenticatedTemplate>
				</div>
			</>
		);
	}

	async signIn(e) {
		if (e !== null && e !== undefined) {
			e.preventDefault();
		}
		const { msal } = this.props;
		this.setState({ ...this.state, error: null, isLoading: true });
		try {
			await msal.loginRedirect(Config.current().authConfig.loginRequest);
		} catch (error) {
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error,
			});
			return;
		}

		await this.getDataAsync();

		this.setState({ ...this.state, isLoading: false });
	}

	componentDidMount() {
		this.getDataAsync();
	}

	async getDataAsync() {

		// const { msal } = this.props;
		// if (msal.getAllAccounts().length === 0) {
		//   console.log("not logged in");
		//   return;
		// }
		const { isAuthenticated } = this.props;
		if (!isAuthenticated) {
			return;
		}

		this.setState({ ...this.state, error: null, isLoading: true });

		// get event info
		let response;
		try {
			response = await axios.get(`${Config.current().apiConfig.webApi}/events/${this.slug}/${this.optionId}`);
		} catch (error) {
			console.log({ error, response: error?.response, code: error?.response?.data?.code, expr: error?.response?.data?.code === "not-found-or-not-valid" });
			if (error?.response?.data?.code === "not-found-or-not-valid") {
				window.location = `${Config.current().portal.baseUrl}events/${this.slug}`;
			} else {
				this.setState({
					...this.state,
					isLoading: false,
					error: error?.response?.data?.error ?? error.message,
				});
			}
			return;
		}
		const eventOption = response.data;


		// get customer info
		const authHeaderValue = await getApiAuthHeaderValue();
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/my-account/customer`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error.response);
			if (error?.response?.status === 401) {
				await this.signIn();
			}
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message,
			});
			return;
		}
		console.log(response);
		const customer = response.data;


		this.setState({
			...this.state,
			isLoading: false,
			eventOption,
			customer,
		});
		// TODO try/catch?
	}
}

