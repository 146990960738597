import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Config } from "./config";

const SignInSignOutButton = () => {
	const { instance } = useMsal();
	return (
		<>
			<AuthenticatedTemplate>
				<Button variant="secondary" onClick={() => instance.logout()} className="ml-auto">Sign Out</Button>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Button variant="secondary" onClick={() => instance.loginRedirect(Config.current().authConfig.loginRequest)} className="ml-auto">Sign In</Button>
				{/* <DropdownButton variant="secondary" className="ml-auto" drop="left" title="Sign In">
					<Dropdown.Item as="button" onClick={() => instance.loginPopup(Config.current().authConfig.loginRequest)}>Sign in using Popup</Dropdown.Item>
					<Dropdown.Item as="button" onClick={() => instance.loginRedirect(Config.current().authConfig.loginRequest)}>Sign in using Redirect</Dropdown.Item>
				</DropdownButton> */}
			</UnauthenticatedTemplate>
		</>
	);
};

function getCookie(cname) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}
function setCookie(cname, cvalue, exdays) {
    const now = new Date();
    now.setTime(now.getTime() + 90 * 24 * 60 * 60 * 1000); // 90 days from now
    const expires = "expires=" + now.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function hasConsentedCookie() {
	let consented = getCookie("cookie-consented");
	return !!consented
}
function setConsentedCookie() {
	setCookie("cookie-consented", "true");
}

export const PageLayout = (props) => {
	const { instance } = useMsal();
	const currentAccounts = instance.getAllAccounts();
	const showAdmin = currentAccounts.length > 0
		&& (currentAccounts[0].username === "stuart@leeks.net" || currentAccounts[0].username === "emilie@leeks.net" || currentAccounts[0].username === "emilie@journeys-in-parenting.com")

	const [consented, setConsentedState] = React.useState(hasConsentedCookie());
	const setConsented = () => {
		setConsentedCookie();
		setConsentedState(true);
	};
	const cookieBanner = consented ? (<></>) : (
		<>
			<div id="cookie-banner">
				<div className="cookie-banner-content">
					<div>This site uses a small number of cookies such as to enable signing in and to aid in tracking errors.</div>
					<div>By continuing on this site you consent to the use of cookies.</div>
					<button id="cookie-accept-button" className="btn btn-primary btn-lg" onClick={()=>setConsented()}>Accept cookies</button>
				</div>
			</div>
		</>
	);

	return (
		<>
			<Navbar bg="primary" variant="dark" id="nav">
				<div className="container">
					<div className="navbar-header">
						<a href="https://emilieleeks.com" className="navbar-brand banner-image">
							<img className="banner" src="/logo512.png" alt="Logo for Emilie Leeks" />
						</a>
						<a className="navbar-brand" href="/">Bookings</a>
					</div>
				</div>
				<a className="navbar-brand" href="https://my.emilieleeks.com">Your resources</a>
				{/* <a className="navbar-brand" href="/my-account">Your account</a> */}
				{showAdmin ? (<a className="navbar-brand" href="/admin">Admin</a>) : (<></>)}
				<SignInSignOutButton />
			</Navbar>
			<div className="container body-content">
				{props.children}
				<div id="response"></div>
				<footer>
					&copy; 2021 - <a href="https://emilieleeks.com">emilieleeks.com</a>
				</footer>
			</div>
			{cookieBanner}
		</>
	);
};


export function logMessage(s) {
	const response = document.getElementById("response");
	response.appendChild(document.createTextNode('\n' + s + '\n'));
}