import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useCallback} from "react";
import {useParams} from "react-router-dom";
import {getApiAuthHeaderValue} from "../api";
import {Config} from "../config";

class EventCoupon {
	id: string = "";
	title: string = "";
	stripeId: string = "";
	stripeUrl: string = "";
}

export default function AdminEventCouponList() {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [error, setError] = useState<any>();
	const [copuons, setCoupons] = useState<EventCoupon[]>();

	const {slug} = useParams() as {slug: string};

	const {instance} = useMsal();
	const signIn = async (e: any) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};
	const loadData = useCallback(async () => {
		setIsLoading(true);
		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/eladmin/events/${slug}/coupons`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		const coupons = response.data.coupons as EventCoupon[];
		setCoupons(coupons);
		setIsLoading(false);
	}, [slug]);
	const isAuthenticated = useIsAuthenticated();
	useEffect(() => {
		if (isAuthenticated)
			loadData();
		return () => {};
	}, [isAuthenticated, loadData]);


	if (!isAuthenticated) {
		return <>
			<h3>Please sign in</h3>
			<div>
				To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
			</div>
		</>
	}

	if (error) {
		return <>
			<h1><a href="/admin">Admin</a> / <a href="/admin/events">Events</a> / <a href={`/admin/events/${slug}`}>{slug}</a> / Coupons</h1>
			{error}
		</>
	}
	let eventList = <></>
	if (copuons) {
		eventList = <>
			<div><a href={`/admin/events/${slug}/coupons/_new`}>Create new event coupon</a></div>
			<table className="table">
				<thead>
					<tr>
						<th>ID</th>
						<th>Title</th>
						<th>StripeID</th>
					</tr>
				</thead>
				<tbody>
					{copuons.map(o => <tr>
						<td><a href={`/admin/events/${slug}/coupons/${o.id}`}>{o.id}</a></td>
						<td>{o.title}</td>
						<td><a href={o.stripeUrl}>{o.stripeId}</a></td>
					</tr>)}
				</tbody>
			</table>
		</>
	}
	return <>
		<h1><a href="/admin">Admin</a> / <a href="/admin/events">Events</a> / <a href={`/admin/events/${slug}`}>{slug}</a> / Coupons</h1>
		{isLoading ? "loading" : ""}
		{eventList}
	</>;
}