import React from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

export class AppInsightsErrorBoundary extends React.Component {
    state = { hasError: false };

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        if (this.props.appInsights) {
            this.props.appInsights.trackException({
                error: error,
                exception: error,
                severityLevel: SeverityLevel.Error,
                properties: { ...info }
            });
            this.props.appInsights.trackEvent({ name: "boundary-error" }, { error: error });
        } else {
            console.log(`*** unable to log error: ${error}`);
        }
    }

    render() {
        if (this.state.hasError) {
            const { onError } = this.props;
            return typeof onError === "function"
                ? onError()
                : React.createElement(onError);
        }

        return this.props.children;
    }
}

