import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {getApiAuthHeaderValue} from "../api";
import {Config} from "../config";

class Migrations {
	pending: string[] = [];
	applied: string[] = [];
}

export default function AdminMigrationsList() {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [error, setError] = useState<any>();
	const [migrations, setMigrations] = useState<Migrations>();

	const {instance} = useMsal();
	const signIn = async (e: any) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};
	const isAuthenticated = useIsAuthenticated();
	useEffect(() => {
		if (isAuthenticated)
			loadData();
		return () => {};
	}, [isAuthenticated]);


	if (!isAuthenticated) {
		return <>
			<h3>Please sign in</h3>
			<div>
				To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
			</div>
		</>
	}

	const loadData = async () => {
		setIsLoading(true);
		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/eladmin/migrations`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		const migrations = response.data.migrations as Migrations;
		setMigrations(migrations);
		setIsLoading(false);
	};
	const runMigrations = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		const authHeaderValue = await getApiAuthHeaderValue();
		try {
			await axios.post(
				`${Config.current().apiConfig.webApi}/eladmin/migrations`,
				null, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (err) {
			console.log(err);
			setIsLoading(false);
			setError(err?.response?.data?.error ?? err.message);
			return;
		}
		await loadData();
	}

	if (error) {
		return <>
			<h1><a href="/admin">Admin</a> / Migrations </h1>
			{error}
		</>
	}
	let migrationList = <></>
	if (migrations) {
		migrationList = <>
			<h2>Pending migrations</h2>
			{migrations.pending.length === 0
				? <div>No pending migrations</div>
				: <>
					<ul>
						{migrations.pending.map(m => <li key={m}>{m}</li>)}
					</ul>
				</>
			}
			<div>
				<form onSubmit={async (e) => runMigrations(e)}>
					<input type="submit" value="Run migrations" />
				</form>
			</div>
			<h2>Applied migrations</h2>
			<ul>
				{migrations.applied.map(m => <li key={m}>{m}</li>)}
			</ul>
		</>
	}
	return <>
		<h1><a href="/admin">Admin</a> / Migrations</h1>
		{isLoading ? "loading" : ""}
		{migrationList}
	</>;
}