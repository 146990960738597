import { format } from 'date-fns'

export function formatStringDate(dateString, formatString){
    format(new Date(Date.parse(dateString)), formatString)
}

export function formatStringDateDMY(dateString) {
    return format(new Date(Date.parse(dateString)), "dd MMM yyyy")
}
export function formatStringDateDMYHm(dateString) {
    return format(new Date(Date.parse(dateString)), "dd MMM yyyy HH:mm")
}
