import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getApiAuthHeaderValue } from "../api";
import { Config } from "../config";

import SimpleCheckbox from "../Components/SimpleCheckbox"

class Event {
	id: string = "";
	title: string = "";
	description: string = "";
	slug: string = "";
}

export default function AdminEventList() {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [error, setError] = useState<any>();
	const [events, setEvents] = useState<Event[]>();
	const [includeArchived, setIncludeArchived] = useState<boolean>(false);
	const { instance } = useMsal();
	const signIn = async (e: any) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};
	const isAuthenticated = useIsAuthenticated();
	

	const loadData = async () => {
		setIsLoading(true);
		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/eladmin/events?include-archived=${includeArchived}`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		const events = response.data.events as Event[];
		setEvents(events);
		setIsLoading(false);
	};
	useEffect(() => {
		if (isAuthenticated)
			loadData();
		return () => { };
	}, [isAuthenticated, includeArchived]);


	if (!isAuthenticated) {
		return <>
			<h3>Please sign in</h3>
			<div>
				To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
			</div>
		</>
	}
	
	

	if (error) {
		return <>
			<h1><a href="/admin">Admin</a> / Events</h1>
			{error}
		</>
	}
	let eventList = <></>
	if (events) {
		eventList = <>
			<div><a href="/admin/events/_new">Create new event</a></div>
			<div className="simple">
				<SimpleCheckbox id="include-archived" labelText="include archived?" value={includeArchived} setValue={setIncludeArchived}/>
			</div>
			<table className="table">
				<thead>
					<tr>
						<th>Slug</th>
						<th>Title</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{events.map(e => <tr key={e.slug}>
						<td><a href={`/admin/events/${e.slug}`}>{e.slug}</a></td>
						<td>{e.title}</td>
						<td><a href={`/admin/bookings?eventId=${e.slug}`}>bookings...</a></td>
					</tr>)}
				</tbody>
			</table>
		</>
	}
	return <>
		<h1><a href="/admin">Admin</a> / Events</h1>
		{isLoading ? "loading" : ""}
		{eventList}
	</>;
}