import React, { Fragment } from "react";
import { Component } from "react";
import axios from 'axios';
import { Config } from '../config'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { getApiAuthHeaderValue } from '../api';
import { GetCard } from '../Common/GetCard'

export const ChangeBookingCard = (props) => {
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const signIn = async (e) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};

	return (
		<>
			<AuthenticatedTemplate>
				<ElementsConsumer>
					{({ elements, stripe }) => (
						<InjectedChangeBookingCard elements={elements} stripe={stripe} msal={instance} isAuthenticated={isAuthenticated} {...props} />
					)}
				</ElementsConsumer>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<h3>Please sign in</h3>
				<div>
					To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
				</div>
			</UnauthenticatedTemplate>
		</>
	);
};
class InjectedChangeBookingCard extends Component {
	state = {
		isLoading: false,
		booking: null,
		customer: null,
		error: null,
	};
	bookingId = this.props.match.params.bookingid;

	render() {
		const { elements } = this.props;

		if (this.state.error !== null) {
			return (
				<div className="row">
					<div className="col-md-12">
						<h3>Error</h3>
						<div>{this.state.error}</div>
					</div>
				</div>
			)
		}
		let changeCardContent = null;
		if (this.state.booking) {
			const booking = this.state.booking;

			if (booking.eventType === "subscription" && (booking.subscriptionStatus === "active" || booking.subscriptionStatus === "past_due")) {
				if (booking.paymentInfo === null) {
					changeCardContent = (<>
						<div>
							<p>No current payment method was found for this booking - select or enter a card below.</p>
						</div>
					</>);
				} else {
					changeCardContent = (<>
						<div>
							<p>The current card for your booking is:</p>
							<p><span className={`card-image card-image-${booking.paymentInfo.brand}`}></span>{booking.paymentInfo.brand} card ****{booking.paymentInfo.last4}</p>
							<p>Expires {booking.paymentInfo.expiryMonth} / {booking.paymentInfo.expiryYear}</p>
						</div>
					</>)
				}
				changeCardContent = (<>
					<h3>Current card</h3>
					{changeCardContent}
					<GetCard elements={elements} onSavedCard={async (card) => this.handleSavedCard(card)} onNewPaymentMethod={async (card) => this.handleNewPaymentMethod(card)} />
				</>)
			}

			if (changeCardContent === null) {
				changeCardContent = (<><p>Payment card can only be updated for active subscriptions</p></>)
			}
		}
		return (
			<div className="row">
				<div className="col-md-12">
					{this.state.isLoading && (<p>processing, please wait...</p>)}
					{changeCardContent}
				</div>
			</div>
		)
	}
	componentDidMount() {
		this.getDataAsync();
	}

	async getDataAsync() {
		const { isAuthenticated } = this.props;
		console.log(`isAuthenticated: ${isAuthenticated}`);
		console.log(this);
		if (!isAuthenticated) {
			return;
		}
		this.setState({ ...this.state, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/my-account/bookings/${this.bookingId}`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message
			});
			return;
		}
		console.log(response);
		const booking = response.data;

		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/my-account/customer`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message
			});
			return;
		}
		console.log(response);
		const customer = response.data;

		this.setState({
			...this.state,
			error: null,
			isLoading: false,
			booking,
			customer,
		});
	}

	async handleSavedCard(card) {
		this.setState({
			...this.state,
			isLoading: true,
		});
		console.log("saved card");
		console.log(card);
		console.log(card.id);

		await this.changeBookingCard(card.id);
	}
	async handleNewPaymentMethod(paymentMethod) {
		this.setState({
			...this.state,
			isLoading: true,
		});
		console.log("new card");

		await this.changeBookingCard(paymentMethod.id);
	}

	async createSetupIntent(paymentMethod) {
		//  - call server to create setupintent and get client secret:  https://stripe.com/docs/api/setup_intents/create?lang=dotnet
		//  - call stripe.confirmCardSetup https://stripe.com/docs/js/setup_intents/confirm_card_setup

		const { stripe, elements } = this.props;
		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			console.log("stripe/elements not loaded");
			return;
		}

		const authHeaderValue = await getApiAuthHeaderValue();
		const response = await axios.post(`${Config.current().apiConfig.webApi}/my-account/cards/create-setup-intent`, null, {
			headers: {
				"Authorization": authHeaderValue,
			}
		});

		if (response.error) {
			// Show error from server on payment form
			console.log("error creating setupintent", response.error); // TODO - log with app insights
			this.setState({
				...this.state,
				isLoading: false,
				error: response.error.message,
			});
			return;
		}
		const clientSecret = response.data.client_secret;
		const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
			payment_method: paymentMethod,
		});
		if (error) {
			// Show error from server on payment form
			console.log("error confirming setupintent", error); // TODO - log with app insights
			this.setState({
				...this.state,
				isLoading: false,
				error: error.message,
			});
			return;
		}
		console.log(setupIntent);

		return setupIntent;
	}
	async changeBookingCard(paymentMethod) {
		const { stripe, elements } = this.props;
		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			console.log("stripe/elements not loaded");
			return;
		}

		await this.createSetupIntent(paymentMethod);

		const authHeaderValue = await getApiAuthHeaderValue();
		const response = await axios.post(`${Config.current().apiConfig.webApi}/my-account/bookings/${this.bookingId}/card`, {
			paymentMethod
		}, {
			headers: {
				"Authorization": authHeaderValue,
			}
		});

		if (response.error) {
			// Show error from server on payment form
			console.log("error creating setupintent", response.error); // TODO - log with app insights
			this.setState({
				...this.state,
				isLoading: false,
				error: response.error.message,
			});
			return;
		}

		if (response.data.requiresAction === true) {
			const clientSecret = response.data.setupIntentClientSecret;
			const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret);
			if (error) {
				// Show error from server on payment form
				console.log("error confirming setupintent", error); // TODO - log with app insights
				this.setState({
					...this.state,
					isLoading: false,
					error: error.message,
				});
				return;
			}
			console.log(setupIntent);
		}

		window.location = `/my-account/bookings/${this.bookingId}`;
	}
}
