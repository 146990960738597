import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useCallback} from "react";
import {useParams} from "react-router-dom";
import {getApiAuthHeaderValue} from "../api";
import {Config} from "../config";
import {formatStringDateDMYHm} from "../utils";

class Card {
	expiryMonth: string = "";
	expiryYear: string = "";
	last4: string = "";
}

class Invoice {
	id: string = "";
	stripeInvoiceUrl: string = "";
	stripeHostedInvoiceUrl: string = "";
	created: string = "";
	createdFriendly: string = "";
	nextPaymentAttempt: string | null = null;
	nextPaymentAttemptFriendly: string | null = null;
	amount: number = -1;
	status: string = "";
	card: Card | null = null;
}

export default function AdminBookingInvoiceShow() {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [error, setError] = useState<any>();
	const [invoice, setInvoice] = useState<Invoice>();

	const {bookingId, invoiceId} = useParams() as {bookingId: string, invoiceId: string};

	const {instance} = useMsal();
	const signIn = async (e: any) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};
		const loadData = useCallback(async () => {
		setIsLoading(true);
		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/eladmin/bookings/${bookingId}/invoices/${invoiceId}`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		const invoice = response.data.invoice as Invoice;
		setInvoice(invoice);
		setIsLoading(false);
	}, [bookingId, invoiceId]);
	const isAuthenticated = useIsAuthenticated();
	useEffect(() => {
		if (isAuthenticated)
			loadData();
		return () => {};
	}, [isAuthenticated, loadData]);


	if (!isAuthenticated) {
		return <>
			<h3>Please sign in</h3>
			<div>
				To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
			</div>
		</>
	}



	let invoiceInfo = <></>
	if (invoice) {
		invoiceInfo = <>
			<div className="row">
				<div className="col-md-12">
					<h3>Invoices:</h3>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<table className="table">
						<tbody>
							<tr>
								<td className="table-label" >Ref</td>
								<td><a href={invoice.stripeInvoiceUrl}>{invoice.id}</a></td>
							</tr>
							<tr>
								<td className="table-label" >Amount</td>
								<td>&pound;{invoice.amount.toFixed(2)}</td>
							</tr>
							<tr>
								<td className="table-label">Created</td>
								<td>{invoice.createdFriendly} ({formatStringDateDMYHm(invoice.created)})</td>
							</tr>
							<tr>
								<td className="table-label" >Stripe invoice:</td>
								<td><a href={invoice.stripeHostedInvoiceUrl}>{invoice.stripeHostedInvoiceUrl}</a></td>
							</tr>
							{invoice.nextPaymentAttempt == null
								? <></>
								: <>
									<tr>
										<td className="table-label">NextPaymentAttempt</td>
										<td>{invoice.nextPaymentAttemptFriendly} ({formatStringDateDMYHm(invoice.nextPaymentAttempt)})</td>
									</tr>
								</>}
							<tr>
								<td className="table-label" >Status</td>
								<td>{invoice.status}</td>
							</tr>
							{invoice.card == null
								? <></>
								: <>
									<tr>
										<td className="table-label">Card Expiry (month/year)</td>
										<td>{invoice.card.expiryMonth} / {invoice.card.expiryYear}</td>
									</tr>
									<tr>
										<td className="table-label">Card Number (last four digits)</td>
										<td>{invoice.card.last4}</td>
									</tr>
								</>}
						</tbody>
					</table>
				</div>
			</div>
		</>
	}
	return <>
		<h1><a href="/admin">Admin</a> / <a href="/admin/bookings">Bookings</a> / <a href={`/admin/bookings/${bookingId}`}>{bookingId}</a> / Invoices / {invoiceId} </h1>
		{isLoading ? "loading" : ""}
		{error ? <div className="alert alert-danger" role="alert" key="error">{error}</div> : <></>}
		{invoiceInfo}
	</>;
}