import { msalInstance } from './msalInstance'
import { logMessage } from './ui'
import { Config } from './config'
import { InteractionRequiredAuthError } from "@azure/msal-common"


function callApi(endpoint, token) {
	const headers = new Headers();
	const bearer = `Bearer ${token}`;

	headers.append("Authorization", bearer);

	const options = {
		method: "GET",
		headers: headers
	};

	logMessage('Calling Web API...');

	fetch(endpoint, options)
		.then(response => response.json())
		.then(response => {

			if (response) {
				logMessage('Web API responded: ' + response.name + ', ' + response.email);
			}

			return response;
		}).catch(error => {
			console.error(error);
		});
}


function getToken(request) {

	const accountSelectorEnvironment = Config.current().accountSelectorEnvironment;

	const currentAccounts = msalInstance.getAllAccounts();

	let accountId = "";
	// let username = "";

	if (currentAccounts.length === 0) {
		return null;
	} else if (currentAccounts.length > 1) {
		// Add your account choosing logic here
		console.info("Multiple accounts detected.", currentAccounts);
		var filteredAccounts = currentAccounts.filter(account => account.environment === accountSelectorEnvironment);
		if (filteredAccounts.length === 1) {
			console.info("Single account found for the current environment", filteredAccounts[0]);
			accountId = filteredAccounts[0].homeAccountId;
		} else {
			if (filteredAccounts.length === 0) {
				console.warn("No accounts found for the current environment");
			} else {
				console.warn("Multiple accounts found for the current environment");
			}
			return null;
		}
	} else if (currentAccounts.length === 1) {
		accountId = currentAccounts[0].homeAccountId;
		// username = currentAccounts[0].username;
	}


	/**
	* See here for more information on account retrieval: 
	* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
	*/

	request.account = msalInstance.getAccountByHomeId(accountId);
	return msalInstance.acquireTokenSilent(request)
		.catch(error => {
			console.warn(error);
			console.warn("silent token acquisition fails. acquiring token using redirect");
			if (error instanceof InteractionRequiredAuthError) {
				// fallback to interaction when silent call fails
				// return msalInstance.acquireTokenPopup(request)
				return msalInstance.acquireTokenRedirect(request)
					.then(response => {
						console.log(response);
						return response;
					}).catch(error => {
						console.error(error);
					});
			} else {
				console.warn(error);
			}
		});
}

export async function passTokenToApi() {
	try {
		let response = await getToken(Config.current().authConfig.tokenRequest);
		if (response) {
			console.log("access_token acquired at: " + new Date().toString());
			callApi(`${Config.current().apiConfig.webApi}/Authenticated`, response.accessToken);
		}
	} catch (error) {
		console.warn(error);
	}
}


async function getApiTestResult_Internal(token) {

	const headers = new Headers();
	const bearer = `Bearer ${token}`;

	headers.append("Authorization", bearer);

	const options = {
		method: "GET",
		headers: headers
	};

	// logMessage('Calling Web API...');

	try {
		// const responseRaw = await fetch(`${Config.current().apiConfig.webApi}/Authenticated`, options);
		const responseRaw = await fetch(`${Config.current().apiConfig.webApi}/EchoClaims`, options);
		const response = await responseRaw.json();

		if (response) {
			// logMessage('Web API responded: ' + response.name + ', ' + response.email);
			return { claims: response.claims };
			// return {
			//     name: response.name,
			//     email: response.email
			// }
		}
	}
	catch (error) {
		console.error(error);
	}
	return null;
}

export async function getApiTestResult() {
	try {
		let response = await getToken(Config.current().authConfig.tokenRequest);
		if (response) {
			console.log("access_token acquired at: " + new Date().toString());
			return await getApiTestResult_Internal(response.accessToken);
		}
	} catch (error) {
		console.warn(error);
	}
	return null;
}


export async function getApiAuthHeaderValue() {
	let response = await getToken(Config.current().authConfig.tokenRequest);
	if (response) {
		return `Bearer ${response.accessToken}`;
	}
	// trigger login if we don't get credentials (e.g. if they've expired)
	await msalInstance.loginRedirect(Config.current().authConfig.loginRequest)
	return null;
}
