import React, { Component } from "react";
import axios from 'axios';
import { Config } from '../config'
import { GetCard } from '../Common/GetCard'
import Button from "react-bootstrap/Button";
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { getApiAuthHeaderValue } from '../api';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { getAppInsights } from '../TelemetryService';


export const EventOptionBookingContent = (props) => {
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const signIn = async (e) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};
	return (
		<>
			<AuthenticatedTemplate>
				<ElementsConsumer>
					{({ elements, stripe }) => (
						<InjectedEventOptionBookingContent elements={elements} stripe={stripe} msal={instance} isAuthenticated={isAuthenticated} {...props} />
					)}
				</ElementsConsumer>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<h3>Please sign in</h3>
				<div>
					To book for events, please <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
				</div>
			</UnauthenticatedTemplate>
		</>
	);
};
class InjectedEventOptionBookingContent extends Component {
	state = {
		// Initial state.
		isLoading: false,
		booking: null,
		error: null,
		voucherInput: null,
	};
	slug = this.props.match.params.slug;
	optionId = this.props.match.params.optionid;
	bookingId = this.props.match.params.bookingid;

	render() {
		let header;
		let stepContent;
		let progress;

		let stateTsAndCs = ""; // TODO - move to state? (set in handlers and use in branching below?)
		let statePayment = "";
		let stateCompleted = "";
		const booking = this.state.booking;

		if (booking) {
			header = (<h2><a href={`/events/${this.state.booking.eventSlug}`}>{this.state.booking.eventName}</a> / {this.state.booking.eventOptionName} </h2>
			)
			if (this.state.error) {
				stepContent = (
					<div className="row">
						<div className="col-md-12">
							<h3 id="error">Error</h3>
							<div>{this.state.error}</div>
							<p><button id="retry-payment" className="btn btn-success" onClick={() => this.retryPayment()} disabled={this.state.isLoading}>Retry payment</button></p>
						</div>
					</div>
				)
			} else if (booking.status === "Aborted") {
				stepContent = (<>
					<div className="row">
						<div className="col-md-12">
							<h3 id="error">Error</h3>
							<div>{booking.statusReason}</div>
							<p>To retry booking, <a id="retry-booking" href={`/events/${this.slug}/${this.optionId}`}>click here</a></p>
						</div>
					</div>
				</>)
			} else {
				if (booking.status === "Completed") {
					stateTsAndCs = "completed";
					statePayment = "completed";
					stateCompleted = "active";
					stepContent = (
						<div className="row">
							<div className="col-md-12">
								<h3>You're booked!</h3>
								<p>Your booking reference is: <strong id="booking-ref">{booking.id}</strong></p>
								<p>You will receive an email from Stripe confirming your payment. You will receive an email from us with information regarding your event once we have processed your booking.</p>
								<p>Click <a href={`/my-account/bookings/${booking.id}`} className="booking-ref-link">here</a> for your booking confirmation</p>
							</div>
						</div>
					)
				} else {
					if (!booking.termsAgreed) {
						stateTsAndCs = "active";
						stepContent = (<>
							<div className="row">
								<div className="col-md-12">
									<p><strong>Click 'I agree' below to agree to the <a href="https://emilieleeks.com/terms-and-conditions/">Terms and Conditions</a></strong></p>
									<p><Button className="btn-success" onClick={() => this.agreeTerms()} disabled={this.state.isLoading}>I agree</Button></p>
								</div>
							</div>
						</>)
					} else {
						stateTsAndCs = "completed";
						statePayment = "active";
						let amountContent;
						if (booking.coupon) {
							amountContent = (
								<>
									<div>Amount: &pound;{booking.price} {booking.intervalSummary} with voucher '{booking.coupon}'</div>
									<div>(Original amount: &pound;{booking.basePrice})</div>
								</>
							)
						} else {
							amountContent = (
								<>
									<div className="row">
										<div className="col-md-12">
											<div>Amount: &pound;{booking.price} {booking.intervalSummary}</div>
											<div>
												{booking.voucherMessage && (
													<div className="card-error" role="alert">
														<p>{booking.voucherMessage}</p>
													</div>
												)}
												<form className="form-inline" onSubmit={(e) => this.handleVoucherSubmit(e)}>
													<label htmlFor="CouponCode">If you have a voucher/discount code, enter it here:</label>
													<input name="CouponCode" className="form-control mb-2 ml-sm-2 mr-sm-2" type="text" onChange={(e) => this.handleCouponChanged(e)} />
													<input type="submit" value="Add voucher" className="btn btn-primary form-control mb-2" />
												</form>
											</div>
										</div>
									</div>
								</>
							)
						}
						const { elements } = this.props;

						stepContent = (
							<>
								<div className="row">
									<div className="col-md-12">
										{/* <h3>Payment method</h3> */}
										To join '{booking.eventName}', please enter your payment details below:
										</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<h3>Payment</h3>
										{amountContent}
									</div>
								</div>
								<GetCard elements={elements} onSavedCard={async (card) => this.handleSubmitSavedCard(card)} onNewCard={async (card) => this.handleSubmitPaymentDetails(card)} />
							</>
						)
					}
				}
				progress = (
					<>
						<ul className="progress-indicator">
							<li className="completed"> <span className="bubble"></span> Start booking</li>
							<li className={stateTsAndCs}> <span className="bubble"></span> Terms and conditions </li>
							<li className={statePayment}> <span className="bubble"></span> Payment details </li>
							<li className={stateCompleted}> <span className="bubble"></span> Completed </li>
						</ul>
					</>)
			}
		}
		return (
			<>
				<div className="App">
					{header}
					{this.state.isLoading && (<><div className="loading"><p></p><div className="spinner" id="spinner"></div>Processing... please do not refresh the page</div><div className="loading-overlay"></div></>)}
					{progress}
					{stepContent}
				</div>
			</>
		);
	}

	async signIn(e) {
		e.preventDefault();
		const ai = getAppInsights();

		const { msal } = this.props;
		this.setState({ ...this.state, error: null, isLoading: true });
		try {
			await msal.loginRedirect(Config.current().authConfig.loginRequest);
		} catch (error) {
			console.log(error);
			ai.trackEvent({ name: "booking" }, { stage: "signin", error: error });
			this.setState({
				...this.state,
				isLoading: false,
				error: error,
			});
			return;
		}
		await this.getDataAsync();
	}


	componentDidMount() {
		this.getDataAsync();
	}

	async getDataAsync() {
		const ai = getAppInsights();
		const { isAuthenticated } = this.props;
		console.log(`isAuthenticated: ${isAuthenticated}`);
		console.log(this);
		if (!isAuthenticated) {
			return;
		}
		this.setState({ ...this.state, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/events/${this.slug}/${this.optionId}/bookings/${this.bookingId}`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error.data);
			ai.trackEvent({ name: "booking" }, { stage: "get-booking", error: error.data });
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message,
			});
			return;
		}
		console.log(response);
		const booking = response.data.booking;
		const paymentIntentAction = response.data.paymentIntentState;

		this.setState({
			...this.state,
			error: null, // reset so that the retryPayment call clears it on success
			booking,
		});

		if (paymentIntentAction) {
			await this.handlePaymentIntentAction(booking, paymentIntentAction);
		}

		this.setState({
			...this.state,
			isLoading: false,
		});
	}

	async retryPayment() {
		await this.getDataAsync();
	}

	async agreeTerms() {
		const ai = getAppInsights();
		this.setState({ ...this.state, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			ai.trackEvent({ name: "booking" }, { stage: "agree-terms" });
			response = await axios.post(
				`${Config.current().apiConfig.webApi}/events/${this.slug}/${this.optionId}/bookings/${this.bookingId}`, {
				"action": "AgreeTerms"
			}, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			ai.trackEvent({ name: "booking" }, { stage: "agree-terms", error: error });
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message,
			});
			return;
		}
		console.log(response);

		this.setState({
			...this.state,
			isLoading: false,
			booking: response.data.booking
		});
	}

	handleCouponChanged(event) {
		this.setState({ voucherInput: event.target.value });
	}
	async handleVoucherSubmit(event) {
		event.preventDefault();
		const ai = getAppInsights();
		this.setState({ ...this.state, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			ai.trackEvent({ name: "booking" }, { stage: "handle-voucher-submit" });
			response = await axios.post(
				`${Config.current().apiConfig.webApi}/events/${this.slug}/${this.optionId}/bookings/${this.bookingId}`, {
				"action": "ApplyVoucher",
				"voucher": this.state.voucherInput,
			}, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			ai.trackEvent({ name: "booking" }, { stage: "handle-voucher-submit", error: error });
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message,
			});
			return;
		}
		console.log(response);

		this.setState({
			...this.state,
			isLoading: false,
			booking: response.data.booking
		});
	}

	async handleSubmitSavedCard(card) {
		const ai = getAppInsights();
		console.log("saved card");
		console.log(card);

		ai.trackEvent({ name: "booking" }, { stage: "saved-card" });

		this.setState({
			...this.state,
			isLoading: true,
			isCompleted: false,
			error: null,
			validationError: null,
		});

		await this.applyPaymentMethod(card.id);
	}
	async handleSubmitPaymentDetails(cardElement) {
		const ai = getAppInsights();
		const { stripe, elements } = this.props;

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			console.log("stripe/elements not loaded");
			return;
		}
		ai.trackEvent({ name: "booking" }, { stage: "payment-method" });

		this.setState({
			...this.state,
			isLoading: true,
			isCompleted: false,
			error: null,
			validationError: null,
		});


		// Create payment method
		const cpmResult = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		});
		console.log("cpmResult", cpmResult);

		if (cpmResult.error) {
			console.log('[cpmResult.error]', cpmResult.error);
			ai.trackEvent({ name: "booking" }, { stage: "payment-method", error: cpmResult.error });
			if (cpmResult.error.type === "validation_error") {
				this.setState({
					...this.state,
					isLoading: false,
					validationError: cpmResult.error.message,
				});
			} else {
				this.setState({
					...this.state,
					isLoading: false,
					error: cpmResult.error,
					isCompleted: true,
				});
			}
			return;
		}
		console.log('[PaymentMethod]', cpmResult.paymentMethod);

		await this.applyPaymentMethod(cpmResult.paymentMethod.id);

		//TODO - error handling!
	};

	async handlePaymentIntentAction(booking, paymentIntentAction) {
		const ai = getAppInsights();
		const { stripe } = this.props;

		if (paymentIntentAction.requires_action) {
			// ********************************************* paymentIntentAction.requires_action *********************************************
			ai.trackEvent({ name: "booking" }, { stage: "payment-intent-action", status: "requires-action" });
			console.log("requires_action");
			// Use Stripe.js to handle the required card action
			const { error: errorAction, paymentIntent } =
				await stripe.handleCardAction(paymentIntentAction.payment_intent_client_secret);

			if (errorAction) {
				ai.trackEvent({ name: "booking" }, { stage: "payment-intent-action", error: errorAction });
				// Show error from Stripe.js in payment form
				console.log("errorAction in hSR", errorAction)
				this.setState({
					...this.state,
					isLoading: false,
					booking,
					error: errorAction.message,
					isCompleted: true,
				});
				return;
			}
			console.log("got payment intent", paymentIntent);
			// The card action has been handled
			// The PaymentIntent can be confirmed again on the server
			const authHeaderValue = await getApiAuthHeaderValue();
			let serverResponse;
			try {
				serverResponse = await axios.post(`${Config.current().apiConfig.webApi}/events/${this.slug}/${this.optionId}/bookings/${this.bookingId}`, {
					"action": "ApplyPaymentIntent",
					paymentIntentId: paymentIntent.id,
				}, {
					headers: {
						"Authorization": authHeaderValue,
					}
				});
			} catch (error) {
				ai.trackEvent({ name: "booking" }, { stage: "apply-payment-intent", error: error });
				console.log(error);
				this.setState({
					...this.state,
					isLoading: false,
					error: error?.response?.data?.error ?? error.message,
					isCompleted: true,
				});
				return;
			}
			await this.handleServerResponse(await serverResponse);
			return;
		} else if (paymentIntentAction.requires_confirm_action) {
			// ********************************************* paymentIntentAction.requires_confirm_action *********************************************
			console.log("requires_confirm_action");
			ai.trackEvent({ name: "booking" }, { stage: "payment-intent-action", status: "requires-confirm-action" });
			// Use Stripe.js to handle the required card action
			const { error: errorAction, paymentIntent } =
				await stripe.confirmCardPayment(paymentIntentAction.payment_intent_client_secret);

			if (errorAction) {
				ai.trackEvent({ name: "booking" }, { stage: "handle-payment-intent-confirm", error: errorAction });
				// Show error from Stripe.js in payment form
				console.log("errorAction in hSR", errorAction)
				this.setState({
					...this.state,
					isLoading: false,
					booking,
					error: errorAction.message,
					isCompleted: true,
				});
				return;
			}
			console.log("got payment intent", paymentIntent);
			// The card action has been handled
			// The PaymentIntent can be confirmed again on the server
			const authHeaderValue = await getApiAuthHeaderValue();
			let serverResponse;
			try {
				serverResponse = await axios.post(`${Config.current().apiConfig.webApi}/events/${this.slug}/${this.optionId}/bookings/${this.bookingId}`, {
					"action": "ApplyPaymentIntent",
					paymentIntentId: paymentIntent.id,
				}, {
					headers: {
						"Authorization": authHeaderValue,
					}
				});
			} catch (error) {
				ai.trackEvent({ name: "booking" }, { stage: "apply-payment-intent-2", error: error });
				console.log(error);
				this.setState({
					...this.state,
					isLoading: false,
					error: error?.response?.data?.error ?? error.message,
					isCompleted: true,
				});
				return
			}
			await this.handleServerResponse(await serverResponse);
			return;
		}

		ai.trackEvent({ name: "booking" }, { stage: "payment-intent-action", status: "polling-fallback" });
		await this.pollForNotPending();
	}

	// handle server response to payment attempts
	async handleServerResponse(response) {
		const ai = getAppInsights();
		console.log("In handleServerResponse")
		if (response.error) {
			ai.trackEvent({ name: "booking" }, { stage: "handle-server-response", error: response.error });
			// Show error from server on payment form
			console.log("error in hSR", response.error); // TODO - log with app insights
			this.setState({
				...this.state,
				isLoading: false,
				error: response.error.message,
				isCompleted: true,
			});
		} else {
			const booking = response.data.booking;
			const pollForNotPending = response.data.pollForNotPending;
			const paymentIntentAction = response.data.paymentIntentState;

			if (pollForNotPending) {
				// success  - poll get-booking for completed state
				await this.pollForNotPending();
			} else if (paymentIntentAction) {
				await this.handlePaymentIntentAction(booking, paymentIntentAction);
			} else {
				// Show success message
				console.log("success");
				this.setState({
					...this.state,
					isLoading: false,
					booking,
					isCompleted: true,
				});
			}
		}
	}

	async applyPaymentMethod(paymentMethodId) {
		const ai = getAppInsights();
		// Send payment method to server
		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.post(`${Config.current().apiConfig.webApi}/events/${this.slug}/${this.optionId}/bookings/${this.bookingId}`, {
				"action": "ApplyPaymentMethod",
				paymentMethodId: paymentMethodId,
			}, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			ai.trackEvent({ name: "booking" }, { stage: "apply-payment-method", error: error });
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message,
				isCompleted: true,
			});
			return;
		}

		await this.handleServerResponse(response);

	}

	sleep(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}
	async pollForNotPending() {
		const ai = getAppInsights();
		ai.trackEvent({ name: "booking" }, { stage: "poll-for-not-pending", status: "start" });
		let pollCount = 1;
		while (true) {
			ai.trackEvent({ name: "booking" }, { stage: "poll-for-not-pending", status: "poll", pollCount: pollCount });
			const authHeaderValue = await getApiAuthHeaderValue();
			let response;
			try {
				response = await axios.get(
					`${Config.current().apiConfig.webApi}/events/${this.slug}/${this.optionId}/bookings/${this.bookingId}`, {
					headers: {
						"Authorization": authHeaderValue,
					}
				});
			} catch (error) {
				ai.trackEvent({ name: "booking" }, { stage: "poll-for-not-pending", error: error });
				console.log(error);
				this.setState({
					...this.state,
					isLoading: false,
					error: error?.response?.data?.error ?? error.message,
				});
				return;
			}
			console.log(response);
			const booking = response.data.booking;
			if (booking.status !== "Pending") {
				ai.trackEvent({ name: "booking" }, { stage: "poll-for-not-pending", status: "complete", pollCount: pollCount  });
				this.setState({
					...this.state,
					isLoading: false,
					booking,
				});
				return;
			}
			await this.sleep(2000);
			pollCount++;
		}
	}
}

