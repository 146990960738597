import React from 'react';
import { ValidationError } from '../Admin/Common';


const FormCheckbox = ({id, labelText, value, setValue, helpText, validationError} :{id: string, labelText: string, value: boolean, setValue: (v:boolean)=>void, helpText?:string, validationError?: JSX.Element}) => (
	<>
		<div className="form-group">
			<label htmlFor={id} className="control-label" >{labelText}</label>
			<input id={id} className={`form-check-input ${ValidationError ? "is-invalid" : ""}`} type="checkbox" defaultChecked={value} onChange={e => {setValue(e.target.checked)} } />
			{
				helpText ? 
				<small className="form-text text-muted">{helpText}</small>
				: <></>
			}
			{validationError}
		</div>
	</>
)

export default FormCheckbox;
