import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import axios, {AxiosResponse} from "axios";
import React, {useEffect, useState} from "react";
import {useCallback} from "react";
import {useParams} from "react-router";
import {getApiAuthHeaderValue} from "../api";
import FormCheckbox from "../Components/FormCheckbox";
import {Config} from "../config";
import {UpdateResponse, ValidationError} from "./Common";


class Event {
	id: string = "";
	title: string = "";
	description: string = "";
	slug: string = "";
	placesLeft: number = -1;
	totalPlaces: number = -1;
	mailChimpAudienceId: string = "";
	mailChimpTag: string = "";
	mailerliteGroupId: string = "";
	timestamp: string = "";
	archived: boolean = false;
}
// class MailChimpAudience {
// 	id: string = "";
// 	name: string = "";
// }
class MailerliteGroup {
	id: string = "";
	name: string = "";
}

interface AdminEventEditOptions {
	isNewEvent: boolean
	loadEventFunction: () => Promise<Event>,
	saveEventFunction: (event: Event) => Promise<UpdateResponse<Event>>
}

export function AdminEventEdit() {
	const {slug} = useParams() as {slug: string};
	async function loadEvent(): Promise<Event> {
		const authHeaderValue = await getApiAuthHeaderValue();
		const response = await axios.get(
			`${Config.current().apiConfig.webApi}/eladmin/events/${slug}`, {
			headers: {
				"Authorization": authHeaderValue,
			}
		});
		const event = response.data.event as Event;
		return event;
	}
	async function saveEvent(event: Event): Promise<UpdateResponse<Event>> {
		const authHeaderValue = await getApiAuthHeaderValue();
		const response: AxiosResponse = await axios.put(
			`${Config.current().apiConfig.webApi}/eladmin/events/${slug}`, event, {
			headers: {
				"Authorization": authHeaderValue,
			}
		});
		const updateResponse = response.data as UpdateResponse<Event>;
		return updateResponse;
	}
	return AdminEventEditCore({
		isNewEvent: false,
		loadEventFunction: useCallback(loadEvent, [slug]),
		saveEventFunction: saveEvent
	})
}
export function AdminEventNew() {
	async function loadEvent(): Promise<Event> {
		const e = new Event();
		return e;
	}
	async function saveEvent(event: Event): Promise<UpdateResponse<Event>> {
		const authHeaderValue = await getApiAuthHeaderValue();
		const response: AxiosResponse = await axios.post(
			`${Config.current().apiConfig.webApi}/eladmin/events`, event, {
			headers: {
				"Authorization": authHeaderValue,
			}
		});
		const updateResponse = response.data as UpdateResponse<Event>;
		return updateResponse;
	}
	return AdminEventEditCore({
		isNewEvent: true,
		loadEventFunction: useCallback(loadEvent, []),
		saveEventFunction: saveEvent
	})
}

function AdminEventEditCore(options: AdminEventEditOptions) {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [error, setError] = useState<any>();
	const [message, setMessage] = useState<string>();
	const [validationErrors, setValidationErrors] = useState<ValidationError[]>();
	const [event, setEvent] = useState<Event>();
	// const [mailChimpAudiences, setMailChimpAudiences] = useState<MailChimpAudience[]>();
	const [mailerliteGroups, setMailerliteGroups] = useState<MailerliteGroup[]>();

	const {slug} = useParams() as {slug: string};

	const {instance} = useMsal();
	const signIn = async (e: any) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};
	const loadEventFunction = options.loadEventFunction;
	const loadData = useCallback( async () => {
		setIsLoading(true);
		const authHeaderValue = await getApiAuthHeaderValue();
		try {
			const event = await loadEventFunction();
			setEvent(event);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}

		// let response;
		// try {
		// 	response = await axios.get(
		// 		`${Config.current().apiConfig.webApi}/eladmin/mailchimp/audiences`, {
		// 		headers: {
		// 			"Authorization": authHeaderValue,
		// 		}
		// 	});
		// } catch (error) {
		// 	console.log(error);
		// 	setIsLoading(false);
		// 	setError(error?.response?.data?.error ?? error.message);
		// 	return;
		// }
		// const audiences = response.data.audiences as MailChimpAudience[];
		// setMailChimpAudiences(audiences);

		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/eladmin/mailerlite/groups`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		const groups = response.data.groups as MailerliteGroup[];
		setMailerliteGroups(groups);


		setIsLoading(false);
	},[loadEventFunction]);


	const isAuthenticated = useIsAuthenticated();
	useEffect(() => {
		if (isAuthenticated) {
			loadData();
		}
		return () => {};
	}, [isAuthenticated, loadData]);


	if (!isAuthenticated) {
		return <>
			<h3>Please sign in</h3>
			<div>
				To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
			</div>
		</>
	}

	const saveEvent = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		setMessage("");

		let response: UpdateResponse<Event>;
		try {
			response = await options.saveEventFunction(event as Event);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		if (response.item !== null) {
			setEvent(response.item);
		}
		if (response.success) {
			if (options.isNewEvent) {
				window.location.assign(`/admin/events/${event?.slug}`);
				return;
			}
			setMessage("Saved");
		}
		setValidationErrors(response.validationErrors);

		setIsLoading(false);
	}

	function isValid(propertyName: string): boolean {
		if (!validationErrors) {
			return true
		}
		return validationErrors.findIndex(e => e.propertyName === propertyName) < 0;
	}
	function validationErrorFor(propertyName: string) {
		return rawValidationErrorFor(propertyName) ?? <></>
	}
	function rawValidationErrorFor(propertyName: string) {
		if (!validationErrors) {
			return undefined;
		}
		const propertyErrors = validationErrors.filter(e => e.propertyName === propertyName);
		if (propertyErrors.length === 0) {
			return undefined;
		}
		return <>
			<div className="invalid-feedback" key={propertyName}>
				{validationErrors
					.filter(e => e.propertyName === propertyName)
					.map((e, i) => <div key={`${propertyName}-${i}`}>{e.errorMessage}</div>)}
			</div>
		</>
	}

	const updateStringProperty = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		let eventTemp = event as any;
		if (eventTemp === undefined) {
			return;
		}
		const target = e.target;
		const name = target.name;


		const newValue = e.target.value as string;

		eventTemp[name] = newValue
		setEvent(eventTemp);
	}
	const updateIntProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
		let eventTemp = event as any;
		if (eventTemp === undefined) {
			return;
		}
		const target = e.target;
		const name = target.name;

		const newValue = e.target.value as string;

		eventTemp[name] = newValue
		setEvent(eventTemp);
	}

	let eventInfo = <></>
	if (event && mailerliteGroups) {
		eventInfo = <>
			<form onSubmit={async (e) => saveEvent(e)}>
				<div className="form-group">
					<label className="control-label" htmlFor="slug">Slug</label>
					<small className="form-text text-muted">The slug is the part of the url for the event, e.g. my-cool-event</small>
					<input className="form-control" disabled={!options.isNewEvent} type="text" id="slug" name="slug" defaultValue={slug} onChange={e => updateStringProperty(e)}></input>
					{validationErrorFor("slug")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="title">Title</label>
					<small className="form-text text-muted">This is the title for pages that show the event</small>
					<input className={`form-control ${isValid("text") ? "" : "is-invalid"}`} type="text" id="title" name="title" defaultValue={event.title} onChange={e => updateStringProperty(e)} ></input>
					{validationErrorFor("title")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="description">Description</label>
					<textarea className={`form-control ${isValid("description") ? "" : "is-invalid"}`} rows={20} cols={40} id="description" name="description" defaultValue={event.description} onChange={e => updateStringProperty(e)}></textarea>
					{validationErrorFor("description")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="placesLeft">Places Left</label>
					<small className="form-text text-muted">The current number of places left for the event (note that bookings in progress are not counted here)</small>
					<input className={`form-control ${isValid("placesLeft") ? "" : "is-invalid"}`} type="text" id="placesLeft" name="placesLeft" defaultValue={event.placesLeft} onChange={e => updateIntProperty(e)} ></input>
					{validationErrorFor("placesLeft")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="totalPlaces">Total Places</label>
					<small className="form-text text-muted">The total number of places for the event</small>
					<input className={`form-control ${isValid("totalPlaces") ? "" : "is-invalid"}`} type="text" id="totalPlaces" name="totalPlaces" defaultValue={event.totalPlaces} onChange={e => updateIntProperty(e)}></input>
					{validationErrorFor("totalPlaces")}
				</div>
				{/* <div className="form-group">
					<label className="control-label" htmlFor="mailChimpAudienceId">MailChimp Audience ID</label>
					<small className="form-text text-muted">The MailChimp Audience/List ID to tag the customer in when they book</small>
					<select className={`form-control ${isValid("mailChimpAudienceId") ? "" : "is-invalid"}`} id="mailChimpAudienceId" name="mailChimpAudienceId" defaultValue={event.mailChimpAudienceId} onChange={e => updateStringProperty(e)}>
						<option></option>
						{mailChimpAudiences.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
					</select>
					{validationErrorFor("mailChimpAudienceId")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="mailChimpTag">MailChimp Tag</label>
					<small className="form-text text-muted">The MailChimp tag to use to tag the customer when they book</small>
					<input className={`form-control ${isValid("mailChimpTag") ? "" : "is-invalid"}`} type="text" id="mailChimpTag" name="mailChimpTag" defaultValue={event.mailChimpTag} onChange={e => updateStringProperty(e)}></input>
					{validationErrorFor("mailChimpTag")}
				</div> */}
				<div className="form-group">
					<label className="control-label" htmlFor="mailerliteGroupId">Mailerlite Group</label>
					<small className="form-text text-muted">The Mailerlite group to add the customer to when they book</small>
					<select className={`form-control ${isValid("mailerliteGroupId") ? "" : "is-invalid"}`} id="mailerliteGroupId" name="mailerliteGroupId" defaultValue={event.mailerliteGroupId} onChange={e => updateStringProperty(e)}>
						<option></option>
						{mailerliteGroups.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
					</select>
					{validationErrorFor("mailChimpTag")}
				</div>
				<FormCheckbox
					id="archived"
					labelText="Is Archived"
					helpText="Archiving an event prevents it from being shown in the admin list (or being booked on)"
					value={event.archived}
					setValue={v=> {const newEvent : Event = {...event, archived:v}; setEvent(newEvent)}}
					validationError={rawValidationErrorFor("isActive")}/>
				<div className="form-group">
					<input type="submit" value="Save" className="btn btn-primary" />
				</div>
				{options.isNewEvent ?
					<></> :
					<>
						<div>
							<a href={`/admin/events/${slug}/options`}>Go to booking options</a>
						</div>
						<div>
							<a href={`/admin/events/${slug}/coupons`}>Go to booking coupons</a>
						</div>
					</>}
			</form>
		</>
	}
	return <>
		<h1><a href="/admin">Admin</a> / <a href="/admin/events">Events</a> / {slug}</h1>
		{isLoading ? "loading" : ""}
		{message ? <div className="alert alert-success" role="alert">{message}</div> : <></>}
		{error ? <div className="alert alert-danger" role="alert" key="error">{error}</div> : <></>}
		{eventInfo}
	</>;
}