import React, { Fragment } from "react";
import { Component } from "react";
import axios from 'axios';
import { Config } from '../config'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { getApiAuthHeaderValue } from '../api';


export const ChangeEmailAddress = (props) => {
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const signIn = async (e) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};

	return (
		<>
			<AuthenticatedTemplate>
				<InjectedChangeEmailAddress msal={instance} isAuthenticated={isAuthenticated} {...props} />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<h3>Please sign in</h3>
				<div>
					To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
				</div>
			</UnauthenticatedTemplate>
		</>
	);
};
class InjectedChangeEmailAddress extends Component {
	state = {
		isLoading: false,
		isCompleted: false,
		customer: null,
		newEmail: null,
		error: null,
	};

	render() {
		if (this.state.error !== null) {
			return (
				<div className="row">
					<div className="col-md-12">
						<h3>Error</h3>
						<div>{this.state.error}</div>
					</div>
				</div>
			)
		}
		if (this.state.isCompleted) {
			return (
				<div className="row">
					<div className="col-md-12">
						<h3>Check your email</h3>
						<p>Check your email ({this.state.newEmail}) for an email with a link to verify your new email address</p>
						<p>Return to <a href="/my-account">Your Account</a></p>
					</div>
				</div>
			)
		}
		let changeEmailContent;
		if (this.state.customer) {
			changeEmailContent = (
				<>
					<h3>New email:</h3>
					<p>Enter your new email address and click Update. We will email a link to your new address - you will need to click this link to confirm the change of email address.</p>
					<form onSubmit={(e) => this.handleSubmit(e)}>
						<div className="form-group">
							<input id="emailAddress" className="form-control" onChange={e => this.handleEmailChanged(e)} />
						</div>
						<div className="form-group">
							<input type="submit" value="Update" className="btn btn-default btn-primary" disabled={this.state.isLoading} />
						</div>
					</form>
				</>

			)
		}
		return (
			<div className="row">
				<div className="col-md-12">
					{this.state.isLoading ? <div>Processing, please wait...<span className="spinner" id="spinner"></span></div> : <></>}
					{changeEmailContent}
				</div>
			</div>
		)
	}
	componentDidMount() {
		this.getDataAsync();
	}

	async getDataAsync() {
		const { isAuthenticated } = this.props;
		console.log(`isAuthenticated: ${isAuthenticated}`);
		console.log(this);
		if (!isAuthenticated) {
			return;
		}
		this.setState({ ...this.state, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/my-account/customer`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message
			});
			return;
		}
		console.log(response);
		const customer = response.data;


		this.setState({
			...this.state,
			error: null, // reset so that the retryPayment call clears it on success
			isLoading: false,
			customer
		});
	}

	async handleEmailChanged(event) {
		this.setState({ newEmail: event.target.value });
	}
	async handleSubmit(event) {
		// Block native form submission.
		event.preventDefault();

		this.setState({ ...this.state, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.post(
				`${Config.current().apiConfig.webApi}/my-account/customer/email`, {
				newEmail: this.state.newEmail
			}, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message
				,
			});
			return;
		}
		console.log(response);

		this.setState({
			...this.state,
			error: null,
			isLoading: false,
			isCompleted: true,
		});
	}

}
