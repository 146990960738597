import React, { Fragment } from "react";
import { Component } from "react";
import axios from 'axios';
import { Config } from '../config'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { getApiAuthHeaderValue } from '../api';
import { formatStringDateDMY } from '../utils'
import { GetCard } from '../Common/GetCard'


export const ShowInvoice = (props) => {
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const signIn = async (e) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};

	return (
		<>
			<AuthenticatedTemplate>
				<ElementsConsumer>
					{({ elements, stripe }) => (
						<InjectedShowInvoice elements={elements} stripe={stripe} msal={instance} isAuthenticated={isAuthenticated} {...props} />
					)}
				</ElementsConsumer>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<h3>Please sign in</h3>
				<div>
					To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
				</div>
			</UnauthenticatedTemplate>
		</>
	);
};
class InjectedShowInvoice extends Component {
	state = {
		isLoading: false,
		showGetCard: false,
		invoice: null,
		error: null,
	};
	bookingId = this.props.match.params.bookingid;
	invoiceId = this.props.match.params.invoiceid;

	render() {
		const { elements } = this.props;

		if (this.state.error !== null) {
			return (
				<div className="row">
					<div className="col-md-12">
						<h3>Error</h3>
						<div>{this.state.error}</div>
					</div>
				</div>
			)
		}
		let invoiceContent;
		if (this.state.invoice) {
			const invoice = this.state.invoice;
			invoiceContent = (
				<>
					<h3><a href="/my-account">Bookings</a> / <a href={`/my-account/bookings/${this.bookingId}`}>{this.bookingId}</a> / Invoice {invoice.number}</h3>
					<table className="table">
						<tbody>
							<tr>
								<td className="table-label">Number</td>
								<td>{invoice.number}</td>
							</tr>
							<tr>
								<td className="table-label">Amount (£)</td>
								<td>{invoice.amount.toFixed(2)}</td>
							</tr>
							<tr>
								<td className="table-label">Created</td>
								<td>{invoice.createdFriendly} ({formatStringDateDMY(invoice.created)})</td>
							</tr>
							<tr>
								<td className="table-label">Stripe invoice</td>
								<td className="wrap-force"><a href={invoice.stripeInvoiceUrl}>{invoice.stripeInvoiceUrl}</a></td>
							</tr>
							{invoice.nextPaymentAttempt === null ? (<></>) : (
								<tr>
									<td className="table-label"></td>
									<td>{formatStringDateDMY(invoice.created)}</td>
								</tr>
							)}
							<tr>
								<td className="table-label">Status</td>
								<td>{invoice.status}</td>
							</tr>
							{invoice.card === null ? (<></>) : (<>
								<tr>
									<td className="table-label">Card Expiry (month/year)</td>
									<td>{invoice.card.expiryMonth} / {invoice.card.expiryYear}</td>
								</tr>
								<tr>
									<td className="table-label">Card number (last four digits)</td>
									<td>{invoice.card.last4}</td>
								</tr>
							</>
							)}
						</tbody >
					</table >
					{invoice?.paymentIntentStatus === "requires_payment_method" || invoice?.paymentIntentStatus === "requires_action" || invoice?.paymentIntentStatus === "requires_confirmation" ? (<> <button className="btn btn-primary" disabled={this.state.loading || this.state.showGetCard} onClick={(e) => this.setState({ ...this.state, showGetCard: true })}>Enter card to pay</button></>) : (<></>)}
					{(invoice?.paymentIntentStatus === "requires_action" || invoice?.paymentIntentStatus === "requires_confirmation") && invoice.paymentIntentCard != null ? (<> <button className="btn btn-primary" disabled={this.state.loading || this.state.showGetCard} onClick={(e) => this.confirmExistingCard(e)}>Confirm with card ending {invoice?.paymentIntentCard?.last4}</button></>) : (<></>)}
				</>)
		}
		return (
			<>
				<div className="row" >
					<div className="col-md-12">
						{this.state.isLoading ? <div>Processing, please wait...<span className="spinner" id="spinner"></span></div> : <></>}
						{invoiceContent}
					</div>
				</div>
				{
					this.state.showGetCard
					&& (<>
						<div className="card-picker">
							<button type="button" className="btn btn-default" disabled={this.state.loading || this.state.showGetCard} onClick={(e) => this.setState({ ...this.state, showGetCard: false })}>Cancel</button>

							<GetCard elements={elements} onSavedCard={async (card) => this.handleSavedCard(card)} onNewPaymentMethod={async (card) => this.handleNewPaymentMethod(card)} />
						</div>
						<div className="card-picker-overlay"></div>
					</>)
				}
			</>
		)
	}
	componentDidMount() {
		this.getDataAsync();
	}

	async getDataAsync() {
		const { isAuthenticated } = this.props;
		console.log(`isAuthenticated: ${isAuthenticated}`);
		console.log(this);
		if (!isAuthenticated) {
			return;
		}
		this.setState({ ...this.state, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/my-account/bookings/${this.bookingId}/invoices/${this.invoiceId}`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message
			});
			return;
		}
		console.log(response);
		const invoice = response.data;


		this.setState({
			...this.state,
			error: null, // reset so that the retryPayment call clears it on success
			isLoading: false,
			invoice
		});
	}

	async confirmExistingCard(event) {
		event.preventDefault();

		const { stripe, elements } = this.props;
		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			console.log("stripe/elements not loaded");
			return;
		}

		this.setState({ ...this.state, isLoading: true });

		// if (this.state.paymentIntentStatus == "requires_action")
		const { error, setupIntent } = await stripe.confirmCardPayment(this.state.invoice.paymentIntentClientSecret);
		if (error) {
			// Show error from server on payment form
			console.log("error confirming payment", error); // TODO - log with app insights
			this.setState({
				...this.state,
				isLoading: false,
				error: error.message,
			});
			return;
		}
		console.log(setupIntent);

		window.location.reload();
	}

	async handleSavedCard(card) {
		this.setState({
			...this.state,
			isLoading: true,
		});
		console.log("saved card");
		console.log(card);
		console.log(card.id);

		await this.payInvoice(card.id);
	}
	async handleNewPaymentMethod(paymentMethod) {
		this.setState({
			...this.state,
			isLoading: true,
		});
		console.log("new card");

		await this.payInvoice(paymentMethod.id);
	}

	async createSetupIntent(paymentMethod) {
		//  - call server to create setupintent and get client secret:  https://stripe.com/docs/api/setup_intents/create?lang=dotnet
		//  - call stripe.confirmCardSetup https://stripe.com/docs/js/setup_intents/confirm_card_setup

		const { stripe, elements } = this.props;
		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			console.log("stripe/elements not loaded");
			return;
		}

		const authHeaderValue = await getApiAuthHeaderValue();
		const response = await axios.post(`${Config.current().apiConfig.webApi}/my-account/cards/create-setup-intent`, null, {
			headers: {
				"Authorization": authHeaderValue,
			}
		});

		if (response.error) {
			// Show error from server on payment form
			console.log("error creating setupintent", response.error); // TODO - log with app insights
			this.setState({
				...this.state,
				isLoading: false,
				showGetCard: false,
				error: response.error.message,
			});
			return;
		}
		const clientSecret = response.data.client_secret;
		const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
			payment_method: paymentMethod,
		});
		if (error) {
			// Show error from server on payment form
			console.log("error confirming setupintent", error); // TODO - log with app insights
			this.setState({
				...this.state,
				isLoading: false,
				showGetCard: false,
				error: error.message,
			});
			return;
		}
		console.log(setupIntent);

		return setupIntent;
	}
	async payInvoice(paymentMethod) {
		const { stripe, elements } = this.props;
		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			console.log("stripe/elements not loaded");
			return;
		}

		const authHeaderValue = await getApiAuthHeaderValue();
		const response = await axios.post(`${Config.current().apiConfig.webApi}/my-account/bookings/${this.bookingId}/invoices/${this.invoiceId}/set-payment-method`, {
			paymentMethod
		}, {
			headers: {
				"Authorization": authHeaderValue,
			}
		});

		if (response.error) {
			// Show error from server on payment form
			console.log("error creating setupintent", response.error); // TODO - log with app insights
			this.setState({
				...this.state,
				isLoading: false,
				showGetCard: false,
				error: response.error.message,
			});
			return;
		}

		if (response.data.requiresAction === true) {
			await stripe.confirmCardPayment(response.data.paymentIntentClientSecret);
		}

		window.location.reload();
	}

}
