import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {getApiAuthHeaderValue} from "../api";
import {Config} from "../config";
import queryString from 'query-string'
import {formatStringDateDMY} from "../utils";

class Booking {
	id: string = "";
	title: string = "";
	eventSlug : string = "";
	created: string = "";
	createdFriendly: string = "";
	customerId : number = -1;
	customerName: string = "";
	customerEmail: string = "";
	stripePaymentId: string = "";
	status: {
		value : string | null,
		valueText : string | null,
		reason : string | null,
	} | null = null;
}

export default function AdminBookingList() {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [error, setError] = useState<any>();
	const [bookings, setBookings] = useState<Booking[]>();

	const queryValues = queryString.parse(window.location.search);

	const {instance} = useMsal();
	const signIn = async (e: any) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};
	const customerId = queryValues.customerId;
	const eventId = queryValues.eventId;
	const loadData = useCallback(async () => {
		setIsLoading(true);
		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			let queryString = "";
			if (customerId) {
				queryString += `&customerId=${customerId}`
			}
			if (eventId) {
				queryString += `&eventId=${eventId}`
			}
			if (queryString !== "") {
				// replace initial '&' with '?'
				queryString = "?" + queryString.substring(1);
			}
			
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/eladmin/bookings${queryString}`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		const bookings = response.data.bookings as Booking[];
		setBookings(bookings);
		setIsLoading(false);
	}, [customerId, eventId]);

	const isAuthenticated = useIsAuthenticated();
	useEffect(() => {
		if (isAuthenticated)
			loadData();
		return () => {};
	}, [isAuthenticated, loadData]);


	if (!isAuthenticated) {
		return <>
			<h3>Please sign in</h3>
			<div>
				To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
			</div>
		</>
	}

	if (error) {
		return <>
			<h1><a href="/admin">Admin</a> / Bookings </h1>
			{error}
		</>
	}
	let bookingList = <></>
	if (bookings) {
		bookingList = <>
			<table className="table">
				<thead>
					<tr>
						<th>Booking Reference</th>
						<th>Course</th>
						<th>Booked</th>
						<th>Booking Name</th>
						<th>Booking Email</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					{bookings.map(o => <tr>
						<td><a href={`/admin/bookings/${o.id}`}>{o.id}</a></td>
						<td><a href={`/admin/events/${o.eventSlug}`}>{o.title}</a></td>
						<td>{o.createdFriendly} ({formatStringDateDMY(o.created)})</td>
						<td><a href={`/admin/customers/${o.customerId}`}>{o.customerName}</a></td>
						<td>{o.customerEmail}</td>
						<td>{o.status?.valueText} {o.status?.reason == null ? <></> : <>({o.status.reason})</>}</td>
					</tr>)}
				</tbody>
			</table>
		</>
	}
	return <>
		<h1><a href="/admin">Admin</a> /Bookings</h1>
		{isLoading ? "loading" : ""}
		{bookingList}
	</>;
}