import React, { Dispatch, SetStateAction } from 'react';


const SimpleCheckbox = ({id, labelText, value, setValue} :{id: string, labelText: string, value: boolean, setValue: Dispatch<SetStateAction<boolean>>}) => (
	<>
		<input id={id} type="checkbox" checked={value} onChange={e => {setValue(!value); console.log(e.target.value)} } />
		<label htmlFor={id}>{labelText}</label>
	</>
)

export default SimpleCheckbox;