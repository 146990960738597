import React, { Fragment } from "react";
import { Component } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

export class Home extends Component {
	render() {
		return (
			<Fragment>
				<h1>Bookings</h1>
				<UnauthenticatedTemplate>
					<div className="row">
						<div className="col-md-12">
							<p>This site allows you to book on events for <a href="https://emilieleeks.com">Emilie Leeks</a>.</p>
						</div>
					</div>
				</UnauthenticatedTemplate>
				<AuthenticatedTemplate>
					<div className="row">
						<div className="col-md-12">
							<div>To see your bookings, see <a href="/my-account">your bookings</a>.</div>
							<div>To access any resources associated with your bookings, see <a href="https://my.emilieleeks.com/">my.emilieleeks.com</a></div>
						</div>
					</div>
				</AuthenticatedTemplate>
			</Fragment>
		);
	}
}
