import React, { Component } from "react";
import axios from 'axios';

import * as MarkdownIt from 'markdown-it';

import { Config } from '../config'

const md = MarkdownIt.default();


export class EventContent extends Component {
  state = {
    // Initial state.
    isLoading: false,
    showStartBooking: false,
    event: null,
    error: null
  };
  slug = this.props.match.params.slug;

  render() {
    let eventInfo;
    if (this.state.error) {
      eventInfo = (
        <>
          <div>Sorry - {JSON.stringify(this.state.error)}</div>
        </>)
    } else if (this.state.event) {
        eventInfo = (<div>
          <h1>{this.state.event.name}</h1>
          <div dangerouslySetInnerHTML={{ __html: md.render(this.state.event.description ?? "") }}></div>
          {this.state.event.options.map(e => (
            <div key={e.id} className="row booking-option">
              <div className="col-md-12">
                <div className="booking-option-title">{e.title}</div>
                <p>Cost: £{e.price} {e.intervalSummary}</p>
                <a className="btn btn-sm btn-success" href={`/events/${this.state.event.slug}/${e.id}`}>Book Now</a>
              </div>
            </div>
          ))}
          {this.state.event.options.length === 0 ? (<p>There are no options to purchase this currently - sorry!</p>) : ""}
        </div>)
    }
    return (
      <div className="App">
				{this.state.isLoading ? <div>Loading...<span className="spinner" id="spinner"></span></div> : <></>}
        {eventInfo}
      </div>
    );
  }

  componentDidMount() {
    this.getDataAsync();
  }

  async getDataAsync() {
    this.setState({ ...this.state, isLoading: true });
    let response;
    try {

      response = await axios.get(`${Config.current().apiConfig.webApi}/events/${this.slug}`);
    } catch (error) {
      console.log(error);
      console.log(error.response);
      this.setState({
        ...this.state,
        isLoading: false,
        error: error?.response?.data?.error ?? error.message,
      });
      return;
    }
    this.setState({
      ...this.state,
      isLoading: false,
      event: response.data
    });
    // TODO try/catch?
  }
}

