import React, { Fragment } from "react";
import { Component } from "react";
import axios from 'axios';
import { Config } from '../config'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { getApiAuthHeaderValue } from '../api';
import { formatStringDateDMY} from '../utils'

export const ShowBooking = (props) => {
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const signIn = async (e) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};

	return (
		<>
			<AuthenticatedTemplate>
				<InjectedShowBooking msal={instance} isAuthenticated={isAuthenticated} {...props} />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<h3>Please sign in</h3>
				<div>
					To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
				</div>
			</UnauthenticatedTemplate>
		</>
	);
};
class InjectedShowBooking extends Component {
	state = {
		isLoading: false,
		booking: null,
		confirmCancel: false,
		error: null,
	};
	bookingId = this.props.match.params.bookingid;

	render() {
		if (this.state.error !== null) {
			return (
				<div className="row">
					<div className="col-md-12">
						<h3>Error</h3>
						<div>{this.state.error}</div>
					</div>
				</div>
			)
		}
		let bookingContent;
		if (this.state.booking) {
			const booking = this.state.booking;
			let chargeStatus;
			let invoices;
			let cancelSubscription;
			let changeCard;
			switch (booking.eventType) {
				case "one-off":
					if (booking.chargeStatus) {
						if (booking.chargeStatus === "succeeded") {
							let voucher = booking.couponCode ? (<> with voucher '{booking.couponCode}'</>) : null;
							let basePrice = booking.couponCode ? (<p>(Original amount &pound;{booking.basePrice.toFixed(2)})</p>) : null;
							chargeStatus = (<td id="payment-status">
								<p>Paid - &pound; {(booking.chargeAmount / 100).toFixed(2)}{voucher}</p>
								{basePrice}
							</td>)
						} else {
							chargeStatus = (<td>{booking.chargeStatus ?? "unknown"}</td>)
						}
					} else {
						if (booking.status === "Pending") {
							chargeStatus = (<td id="payment-status"><div className="badge badge-warning">Booking in progress</div><p><a href={`/events/${booking.eventSlug}/${booking.eventOptionId}/${booking.id}`}>click here to continue booking</a></p></td>)
						} else {
							chargeStatus = (<td id="payment-status">No payment found - {booking.paymentFailureMessage}</td>)
						}
					}
					break;

				case "subscription":
					if (booking.status === "Pending") {
						chargeStatus = (<td id="payment-status"><div className="badge badge-warning">Booking in progress</div><p><a href={`/events/${booking.eventSlug}/${booking.eventOptionId}/${booking.id}`}>click here to continue booking</a></p></td>)
					} else  if (booking.status === "Aborted") {
						chargeStatus = (<td id="payment-status">expired/aborted</td>)
					} else if (booking.subscriptionStatus === "active") {
						if (booking.subscriptionWillCancelAt === null){
							cancelSubscription = (<>
								<p><button className="btn btn-danger" onClick={(e) => this.setState({...this.state, confirmCancel:true})}>Cancel subscription</button></p>
								{this.state.confirmCancel 
									&& (<>
										<div className="loading">
											<h4>Confirm cancellation</h4>
											<p>Are you sure you wish to cancel your subscription</p>
											<p>NOTE: any discounts applied to this booking may no longer be available should you wish to rebook.</p>
											<button type="button" className="btn btn-danger" onClick={(e) => this.cancelBooking(e)}>Confirm</button>&nbsp;
											<button type="button" className="btn btn-default" onClick={(e) => this.setState({...this.state, confirmCancel:false})}>Return</button>
										</div>
										<div className="loading-overlay"></div>
									</>)}
							</>)
						}
						chargeStatus = (<td id="payment-status">Active {booking.subscriptionWillCancelAt === null ? (<></>) : (<> <span className="badge badge-info">cancels on {formatStringDateDMY(booking.subscriptionWillCancelAt)}</span></>)} </td>)
					} else if(booking.subscriptionStatus === "canceled") {
						chargeStatus = (<td id="payment-status">Canceled {booking.subscriptionCanceledFriendly} ({formatStringDateDMY(booking.subscriptionCanceled)}) </td>)
					} else {
						chargeStatus = (<td id="payment-status">{booking.subscriptionStatus}</td>)
					}

					if ((booking.subscriptionStatus === "active" || booking.subscriptionStatus === "past_due") 
						 &&  booking.subscriptionWillCancelAt === null){
							changeCard = (<>
								<p><a className="btn btn-primary" href={`/my-account/bookings/${this.bookingId}/change-card`}>Change payment card</a></p>
							</>)
					}

					if (booking.invoices === null || booking.invoices.length === 0){
						invoices = (<><h4>Invoices</h4><p>No invoices</p></>)
					} else {
						invoices = (<>
								<h4>Invoices</h4>
								<table className="table">
									<thead>
										<tr>
											<td>Invoice</td>
											<td>Date</td>
											<td>Amount (£)</td>
											<td>Status</td>
										</tr>
									</thead>
									<tbody>
										{booking.invoices.map(i =>(<tr key={i.id}>
											<td><a href={`/my-account/bookings/${this.bookingId}/invoices/${i.id}`}>{i.number}</a></td>
											<td>{i.createdFriendly} ({formatStringDateDMY(i.created)})</td>
											<td>{i.amount.toFixed(2)}</td>
											<td>{i.stripeStatus}
											{i.stripeStatus === "open" ? (<> <a href={`/my-account/bookings/${this.bookingId}/invoices/${i.id}`} className="badge badge-danger">Needs attention</a></>) : (<></>) }
											</td>
										</tr>))}
									</tbody>
								</table>
					</>)
					}
					break;
			
				default:
					chargeStatus = (<td>Unhandled booking type: '{booking.eventType}'</td>)
					break;
			}
			let chargeReceipt;
			if (booking.chargeReceiptUrl) {
				chargeReceipt = (<tr>
					<td>Receipt</td>
					<td><a href={booking.chargeReceiptUrl}>{booking.chargeReceiptUrl}</a></td>
				</tr>)
			}
			bookingContent = (
				<>
					<h3><a href="/my-account">Bookings</a> / {booking.id}</h3>
					<table className="table">
						<tbody>
							<tr>
								<td className="table-label">Ref</td>
								<td id="booking-reference">{booking.id}</td>
							</tr>
							<tr>
								<td className="table-label">Name</td>
								<td id="event-name">{booking.eventTitle} ({booking.eventOptionTitle})</td>
							</tr>
							<tr>
								<td className="table-label">Created</td>
								<td>{booking.createdFriendly} ({formatStringDateDMY(booking.created)})</td>
							</tr>
							<tr>
								<td className="table-label">Status</td>
								{chargeStatus}
							</tr>
							{chargeReceipt}
							{ booking.paymentInfo === null ? (<></>) : (<>
								<tr>
									<td className="table-label">Card Expiry (month/year)</td>
									<td id="card-expiry">{booking.paymentInfo.expiryMonth} / {booking.paymentInfo.expiryYear}</td>
								</tr>
								<tr>
									<td className="table-label">Card number (last four digits)</td>
									<td id="card-last4">{booking.paymentInfo.last4}</td>
								</tr>
								</>)}
						</tbody>
					</table>
					{cancelSubscription} {changeCard}
					{invoices}
				</>

			)
		}
		return (
			<div className="row">
				<div className="col-md-12">
					{this.state.isLoading ? <div>Processing, please wait...<span className="spinner" id="spinner"></span></div> : <></>}
					{bookingContent}
				</div>
			</div>
		)
	}
	componentDidMount() {
		this.getDataAsync();
	}

	async getDataAsync() {
		const { isAuthenticated } = this.props;
		console.log(`isAuthenticated: ${isAuthenticated}`);
		console.log(this);
		if (!isAuthenticated) {
			return;
		}
		this.setState({ ...this.state, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/my-account/bookings/${this.bookingId}`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message
			});
			return;
		}
		console.log(response);
		const booking = response.data;


		this.setState({
			...this.state,
			error: null, // reset so that the retryPayment call clears it on success
			isLoading: false,
			booking
		});
	}

	async cancelBooking(e) {
		e.preventDefault();
		this.setState({ ...this.state, error: null, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
		  response = await axios.post(
			`${Config.current().apiConfig.webApi}/my-account/bookings/${this.bookingId}/cancel`, null, {
			headers: {
			  "Authorization": authHeaderValue,
			}
		  });
		} catch (error) {
		  console.log(error);
		  this.setState({
			...this.state,
			isLoading: false,
			error: error?.response?.data?.error ?? error.message,
		  });
		  return;
		}
		console.log(response);
		const booking = response.data;
	
		this.setState({
		  ...this.state,
		  isLoading: false,
		  booking
		});
	  }
	
}
