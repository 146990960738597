import React, { Component } from "react";
import axios from 'axios';
import { Config } from '../config'
import { CardNumberElement, CardExpiryElement, CardCvcElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { getApiAuthHeaderValue } from '../api';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";


export const GetCard = (props) => {
    const isAuthenticated = useIsAuthenticated();
    return (
        <>
            <AuthenticatedTemplate>
                <ElementsConsumer>
                    {({ elements, stripe }) => (
                        <InjectedGetCard elements={elements} stripe={stripe} isAuthenticated={isAuthenticated} {...props} />
                    )}
                </ElementsConsumer>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
            </UnauthenticatedTemplate>
        </>
    );
};


class InjectedGetCard extends Component {
    state = {
        // Initial state.
        isLoading: false,
        customer: null,
        error: null,
    };

    render() {
        const { stripe } = this.props;
        const ELEMENT_OPTIONS = {
            style: {
                base: {
                    fontSize: '18px',
                    color: '#424770',
                    letterSpacing: '0.025em',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };

        if (this.state.customer === null) {
            return (
                <>
                    <div>Loading...</div>
                </>
            )
        }

        let savedCards;
        if (this.state.customer.cards.length > 0) {
            savedCards = (
                <>
                    <div className="row">
                        <div className="col-md-12">
                            <h4>Use saved card</h4>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.customer.cards.map(c => (
                            <div className="col-md-4 saved-card-container" key={c.id} data-fingerprint={`${c.last4}-${c.expiryYear}-${c.expiryMonth}`}>
                                <div className="saved-card">
                                    <form onSubmit={async (e) => this.handleSavedCard(e, c)}>
                                        <p><span className={`card-image card-image-${c.brand}`}></span>ending {c.last4}<wbr/><span className="card-line-2">Expires {c.expiryMonth} / {c.expiryYear}</span></p>
                                        <button type="submit" className="btn btn-success" disabled={!stripe || this.state.isLoading}>
                                            <span>
                                                {this.state.isLoading ? (
                                                    <div className="spinner" id="spinner"></div>
                                                ) : (<></>)}Use this card
                                            </span>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        ))}
                    </div>
                </>)
        } else {
            savedCards = (
                <div className="row">
                    <div className="col-md-12">
                        <h4>Use saved card</h4>
                        <p>No saved cards</p>
                    </div>
                </div>)
        }

        return (<>
            {savedCards}
            <div className="row">
                <div className="col-md-12">
                    <h4>Enter a new card</h4>
                    <form onSubmit={async (e) => this.handleNewCard(e)} className="pay">
                        <label htmlFor="cardNumber">Card Number</label>
                        <CardNumberElement id="cardNumber" options={ELEMENT_OPTIONS} />
                        <label htmlFor="cardExpiry">Card Expiry Date</label>
                        <CardExpiryElement id="cardExpiry" options={ELEMENT_OPTIONS} />
                        <label htmlFor="cardExpiry">CVC (3 digits on the back of your card)</label>
                        <CardCvcElement id="cardCvc" options={ELEMENT_OPTIONS} />
                        {this.state.validationError && (
                            <div className="card-error" role="alert">
                                {this.state.validationError}
                            </div>
                        )}
                        <button id="payment-request-button" type="submit" className="btn btn-success pay" disabled={!stripe || this.state.isLoading}>
                            <div>
                                {this.state.isLoading ? (
                                    <span className="spinner" id="spinner"></span>
                                ) : (<></>)}Pay with new card
                        </div>
                        </button>
                    </form>
                    <p>Your credit card details will be passed securely to our payments provider (Stripe) - emilieleeks.com does not process your credit card information.</p>
                </div>
            </div>
        </>
        );
    }

    componentDidMount() {
        this.getDataAsync();
    }
    async getDataAsync() {
        const { isAuthenticated } = this.props;
        if (!isAuthenticated) {
            return;
        }
        this.setState({ ...this.state, isLoading: true });

        const authHeaderValue = await getApiAuthHeaderValue();
        let response;

        try {
            response = await axios.get(
                `${Config.current().apiConfig.webApi}/my-account/customer`, {
                headers: {
                    "Authorization": authHeaderValue,
                }
            });
        } catch (error) {
            console.log(error.data);
            this.setState({
                ...this.state,
                isLoading: false,
                error: error?.response?.data?.error ?? error.message,
            });
            return;
        }
        console.log(response);
        const customer = response.data;

        this.setState({
            ...this.state,
            error: null,
            customer,
            isLoading: false,
        });
    }
    async handleSavedCard(event, card) {
        event.preventDefault();
        this.setState({ ...this.state, isLoading: true });

        const { onSavedCard } = this.props;
        await onSavedCard(card);

        this.setState({ ...this.state, isLoading: false });
    }
    async handleNewCard(event) {
        event.preventDefault();
        const { stripe, elements } = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            console.log("stripe/elements not loaded");
            return;
        }

        this.setState({ ...this.state, isLoading: true });

        const cardElement = elements.getElement(CardNumberElement);
        const { onNewCard, onNewPaymentMethod } = this.props;

        if (onNewCard !== null && onNewCard !== undefined) {
            await onNewCard(cardElement);
        } else {
            const cpmResult = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (cpmResult.error) {
                console.log('[cpmResult.error]', cpmResult.error);
                if (cpmResult.error.type === "validation_error") {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        validationError: cpmResult.error.message,
                    });
                } else {
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        error: cpmResult.error,
                        isCompleted: true,
                    });
                }
                return;
            }
            await onNewPaymentMethod(cpmResult.paymentMethod);
        }

        this.setState({ ...this.state, isLoading: false });
    }
}  