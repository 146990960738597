import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getApiAuthHeaderValue} from "../api";
import {Config} from "../config";
import {formatStringDateDMY} from "../utils";

enum EventType {
	Subscription = 1,
	OneOff = 2,
}
enum BookingStatusValue {
	Pending = 0,
	Aborted = 1,
	Completed = 2,
	Cancelled = 3
}
class BookingCommon {
	id: string = "";
	eventSlug: string = "";
	eventTitle: string = "";
	eventType: EventType = -1;
	eventOptionId: string = "";
	eventOptionTitle: string = "";
	customerId: string = "";
	customerName: string = "";
	customerEmail: string = "";
	created: string = "";
	createdFriendly: string = "";
	bookingStatus: BookingStatusValue = -1;
	stripePaymentId: string | null = null;
	stripePaymentUrl: string | null = null;
	couponId: string | null = null;
	couponTitle: string | null = null;
	paymentFailureMessage: string | null = null;
}
class BookingOneOff extends BookingCommon {
	stripeChargeStatus: string | null = null;
	stripeChargeAmount: number | null = null;
	basePrice: number = -1;
	actualPrice: number = -1;
	amountPaid: number | null = null;
	stripeReceiptUrl: string | null = null;
}
class Invoice {
	id: string = "";
	created: string = "";
	createdFriendly: string = "";
	amount: number = -1;
	status: string = "";
}
class BookingSubscription extends BookingCommon {
	currentPeriodEnd: string = "";
	currentPeriodEndFriendly: string = "";
	stripeSubscriptionStatus: string = "";
	cancelledAt: string | null = null;
	cancelledAtFriendly: string | null = null;
	invoices: Invoice[] = [];
}
type Booking = BookingOneOff | BookingSubscription;

export default function AdminBookingShow() {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [error, setError] = useState<any>();
	const [booking, setBooking] = useState<Booking>();

	const {bookingId} = useParams() as {bookingId: string};

	const {instance} = useMsal();
	const signIn = async (e: any) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};
	const loadData = useCallback(async () => {
		setIsLoading(true);
		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/eladmin/bookings/${bookingId}`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		const booking = response.data.booking as Booking;
		setBooking(booking);
		setIsLoading(false);
	}, [bookingId]);

	const isAuthenticated = useIsAuthenticated();
	useEffect(() => {
		if (isAuthenticated)
			loadData();
		return () => {};
	}, [isAuthenticated, loadData]);


	if (!isAuthenticated) {
		return <>
			<h3>Please sign in</h3>
			<div>
				To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
			</div>
		</>
	}
	if (error) {
		return <>
			<h1><a href="/admin">Admin</a> / <a href="/admin/bookings">Bookings</a> / {bookingId} </h1>
			{error}
		</>
	}

	let invoiceInfo = <></>
	let bookingInfo = <></>
	if (booking) {
		const typeSummary = booking.eventType === EventType.OneOff ? getSummaryOneOff(booking as BookingOneOff) : getSummarySubscription(booking as BookingSubscription);
		if (booking.eventType === EventType.Subscription) {
			const invoices = (booking as BookingSubscription).invoices;
			invoiceInfo = <>
				<div className="row">
					<div className="col-md-12">
						<h3>Invoices:</h3>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						{invoices.length === 0
							? <>No invoices</>
							: <>

								<table className="table">
									<thead>
										<tr>
											<td>Invoice</td>
											<td>Date</td>
											<td>Amount (£)</td>
											<td>Status</td>
										</tr>
									</thead>
									<tbody>
										{invoices.map(i => <tr>
											<td><a href={`/admin/bookings/${booking.id}/invoices/${i.id}`}>{i.id}</a></td>
											<td>{i.createdFriendly} ({formatStringDateDMY(i.created)})</td>
											<td>&pound;{i.amount.toFixed(2)}</td>
											<td>{i.status}</td>
										</tr>)}
									</tbody>
								</table>
							</>}
					</div>
				</div>
			</>
		}
		bookingInfo = <>
			<div className="row">
				<div className="col-md-12">
					<table className="table">
						<tr>
							<td className="table-label">Ref</td>
							<td>{booking.id}</td>
						</tr>
						<tr>
							<td className="table-label">Event</td>
							<td><a href={`/admin/events/${booking.eventSlug}`}>{booking.eventTitle}</a> (<a href={`/admin/events/${booking.eventSlug}/options/${booking.eventOptionId}`}>{booking.eventOptionTitle}</a>)</td>
						</tr>
						<tr>
							<td className="table-label">Name</td>
							<td><a href={`/admin/customers/${booking.customerId}`}>{booking.customerName}</a></td>
						</tr>
						<tr>
							<td className="table-label">Email</td>
							<td>{booking.customerEmail}</td>
						</tr>
						<tr>
							<td className="table-label">Created</td>
							<td>{booking.createdFriendly} ({formatStringDateDMY(booking.created)})</td>
						</tr>
						<tr>
							<td className="table-label">Coupon</td>
							<td>{booking.couponId === null ? <></> : <><a href={`/admin/events/${booking.eventSlug}/coupons/${booking.couponId}`}>{booking.couponId}</a></>}</td>
						</tr>
						{typeSummary}
					</table>
				</div>
			</div>
		</>
	}
	return <>
		<h1><a href="/admin">Admin</a> / <a href="/admin/bookings">Bookings</a> / {bookingId} </h1>
		{isLoading ? "loading" : ""}
		{bookingInfo}
		{invoiceInfo}
	</>;
}

function getSummaryOneOff(booking: BookingOneOff) {
	const getStripeStatusSummary = (booking: BookingOneOff) => {
		if (booking.stripeChargeStatus) {
			if (booking.stripeChargeStatus === "succeeded") {
				return <td>Paid - &pound; {booking.stripeChargeAmount?.toFixed(2)}</td>
			}
			return <td>{booking.stripeChargeStatus}</td>
		} else {
			if (booking.bookingStatus === BookingStatusValue.Pending) {
				return <td>Booking in progress</td>
			}
			return <td>No payment found - {booking.paymentFailureMessage}</td>
		}
	}
	return <>
		<tr>
			<td className="table-label">Type</td>
			<td>OneOff</td>
		</tr>
		<tr>
			<td className="table-label">Base Price</td>
			<td>&pound;{booking.basePrice.toFixed(2)}</td>
		</tr>
		<tr>
			<td className="table-label">Actual Price</td>
			<td>&pound;{booking.actualPrice.toFixed(2)}</td>
		</tr>
		<tr>
			<td className="table-label">Stripe Status</td>
			{getStripeStatusSummary(booking)}
		</tr>
		<tr>
			<td className="table-label">Receipt</td>
			<td>{booking.stripeReceiptUrl == null ? <></> : <><a href={booking.stripeReceiptUrl}>{booking.stripeReceiptUrl}</a></>}</td>
		</tr>
		<tr>
			<td className="table-label">Stripe Payment</td>
			<td>{booking.stripePaymentUrl == null ? <></> : <><a href={booking.stripePaymentUrl}>{booking.stripePaymentId}</a></>}</td>
		</tr>
	</>
}

function getSummarySubscription(booking: BookingSubscription) {
	const getStripeStatusSummary = (booking: BookingSubscription) => {
		if (booking.stripeSubscriptionStatus) {
			switch (booking.stripeSubscriptionStatus) {
				case "active":
					return <td>Active - current period ends: {booking.currentPeriodEndFriendly} ({formatStringDateDMY(booking.currentPeriodEnd)})</td>
				case "canceled":
					return <td>Cancelled : {booking.cancelledAtFriendly} ({formatStringDateDMY(booking.cancelledAt)})</td>
				default:
					return <td>{booking.stripeSubscriptionStatus}</td>
			}
		} else {
			if (booking.bookingStatus === BookingStatusValue.Pending) {
				return <td>Booking in progress</td>
			}
			return <td>No payment found - {booking.paymentFailureMessage}</td>
		}
	}
	return <>
		<tr>
			<td>Type</td>
			<td>Subscription</td>
		</tr>
		<tr>
			<td className="table-label">Current period end</td>
			<td>{booking.currentPeriodEnd ? <>{booking.currentPeriodEndFriendly} ({formatStringDateDMY(booking.currentPeriodEnd)}) </> : <></>}</td>
		</tr>
		<tr>
			<td className="table-label">Stripe Status</td>
			{getStripeStatusSummary(booking)}
		</tr>
		<tr>
			<td className="table-label">Stripe Subscription</td>
			<td>{booking.stripePaymentUrl == null ? <></> : <><a href={booking.stripePaymentUrl}>{booking.stripePaymentId}</a></>}</td>
		</tr>
	</>
}
