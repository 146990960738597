import React, { Fragment } from "react";
import { Component } from "react";
import axios from 'axios';
import { Config } from '../config'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { getApiAuthHeaderValue } from '../api';
import { formatStringDateDMY } from '../utils'

export const YourAccountContent = (props) => {
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const signIn = async (e) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};

	return (
		<>
			<AuthenticatedTemplate>
				<InjectedYourAccountContent msal={instance} isAuthenticated={isAuthenticated} {...props} />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<h3>Please sign in</h3>
				<div>
					To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
				</div>
			</UnauthenticatedTemplate>
		</>
	);
};
class InjectedYourAccountContent extends Component {
	state = {
		isLoading: false,
		customer: null,
		error: null,
	};

	render() {
		if (this.state.error) {
			return (
				<div className="row">
					<div className="col-md-12">
						<h3>Error</h3>
						<div>{this.state.error}</div>
					</div>
				</div>
			)
		}
		if (this.state.isLoading) {
			return (<h2>Loading...</h2>)
		}
		if (this.state.customer !== null) {
			const bookings = this.state.bookings.length === 0
				? (<><p>No bookings</p></>)
				: (<>
					<table className="table">
						<thead>
							<tr>
								<td>Booking reference</td>
								<td>Event</td>
								<td>Booked</td>
								<td>Status</td>
							</tr>
						</thead>
						<tbody>
							{this.state.bookings.map(b => (
								<tr key={b.id}>
									<td><a href={`/my-account/bookings/${b.id}`}>{b.id}</a></td>
									<td>{b.title}</td>
									<td>{b.createdFriendly} ({formatStringDateDMY(b.created)})</td>
									<td>{b.stripeStatus}
										{b.subscriptionWillCancelAt == null ? (<></>) : (<> <span className="badge badge-info">cancels on {formatStringDateDMY(b.subscriptionWillCancelAt)}</span></>)}
										{b.stripeStatus === "Pending" ? (<> <a className="badge badge-warning" href={`/events/${b.eventSlug}/${b.eventOptionId}/${b.id}`}>Booking in progress</a></>) : (<></>)}
										{b.stripeStatus === "past_due" || b.hasOpenInvoices ? (<> <a className="badge badge-danger" href={`/my-account/bookings/${b.id}`}>Needs attention</a></>) : (<></>)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</>);
			return (
				<Fragment>
					<h1>Your Bookings Account</h1>
					<div className="row">
						<div className="col-md-12">
							<h2>Saved Cards</h2>
							<p>You have {this.state.customer.cards.length} saved cards. To edit your cards <a href="/my-account/cards">click here</a>.</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<h2>Email Address</h2>
							<p>The email address we have for you is {this.state.customer.email}</p>
							<p><a href="/my-account/change-email-address" className="btn btn-primary">Change email address</a></p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<h2>Bookings</h2>
							{bookings}
						</div>
					</div>
				</Fragment>
			);
		}
		return (
			<Fragment>
				<p>Customer not loaded...</p>
			</Fragment>
		)
	}
	componentDidMount() {
		this.getDataAsync();
	}

	async getDataAsync() {
		const { isAuthenticated } = this.props;
		console.log(`isAuthenticated: ${isAuthenticated}`);
		console.log(this);
		if (!isAuthenticated) {
			return;
		}
		this.setState({ ...this.state, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/my-account/customer`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message
			});
			return;
		}
		console.log(response);
		const customer = response.data;


		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/my-account/bookings`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message
			});
			return;
		}
		console.log(response);
		const bookings = response.data.bookings;

		this.setState({
			...this.state,
			error: null, // reset so that the retryPayment call clears it on success
			isLoading: false,
			customer,
			bookings,
		});
	}

}
