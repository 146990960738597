import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import axios, {AxiosResponse} from "axios";
import React, {useEffect, useState} from "react";
import {useCallback} from "react";
import {useParams} from "react-router";
import {getApiAuthHeaderValue} from "../api";
import {Config} from "../config";
import {UpdateResponse, ValidationError} from "./Common";

class EventCoupon {
	id: string = "";
	title: string = "";
	stripeCouponId: string = "";
	isActive: boolean = true;
	validFrom?: string;
	validUntil?: string;
}

interface AdminEventCouponEditOptions {
	isNewCoupon: boolean
	loadEventCouponFunction: () => Promise<EventCoupon>,
	saveEventCouponFunction: (event: EventCoupon) => Promise<UpdateResponse<EventCoupon>>
}

export function AdminEventCouponEdit() {
	const {slug, couponId} = useParams() as {slug: string, couponId: string};
	async function loadEventCoupon(): Promise<EventCoupon> {
		const authHeaderValue = await getApiAuthHeaderValue();
		const response = await axios.get(
			`${Config.current().apiConfig.webApi}/eladmin/events/${slug}/coupons/${couponId}`, {
			headers: {
				"Authorization": authHeaderValue,
			}
		});
		const coupon = response.data.coupon as EventCoupon;
		console.log(coupon);
		return coupon;
	}
	async function saveEventCoupon(coupon: EventCoupon): Promise<UpdateResponse<EventCoupon>> {
		const authHeaderValue = await getApiAuthHeaderValue();
		const response: AxiosResponse = await axios.put(
			`${Config.current().apiConfig.webApi}/eladmin/events/${slug}/coupons/${couponId}`, coupon, {
			headers: {
				"Authorization": authHeaderValue,
			}
		});
		const updateResponse = response.data as UpdateResponse<EventCoupon>;
		return updateResponse;
	}
	return AdminEventCouponEditCore({
		isNewCoupon: false,
		loadEventCouponFunction: useCallback(loadEventCoupon, [slug, couponId]),
		saveEventCouponFunction: saveEventCoupon
	})
}
export function AdminEventCouponNew() {
	const {slug} = useParams() as {slug: string};
	async function loadEventCoupon(): Promise<EventCoupon> {
		return new EventCoupon();
	}
	async function saveEventCoupon(coupon: EventCoupon): Promise<UpdateResponse<EventCoupon>> {
		const authHeaderValue = await getApiAuthHeaderValue();
		const response: AxiosResponse = await axios.post(
			`${Config.current().apiConfig.webApi}/eladmin/events/${slug}/coupons`, coupon, {
			headers: {
				"Authorization": authHeaderValue,
			}
		});
		const updateResponse = response.data as UpdateResponse<EventCoupon>;
		return updateResponse;
	}
	return AdminEventCouponEditCore({
		isNewCoupon: true,
		loadEventCouponFunction: useCallback(loadEventCoupon, []),
		saveEventCouponFunction: saveEventCoupon
	})
}

function AdminEventCouponEditCore(options: AdminEventCouponEditOptions) {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [error, setError] = useState<any>();
	const [message, setMessage] = useState<string>();
	const [validationErrors, setValidationErrors] = useState<ValidationError[]>();
	const [coupon, setCoupon] = useState<EventCoupon>();

	const {slug, couponId} = useParams() as {slug: string, couponId: string};

	const {instance} = useMsal();
	const signIn = async (e: any) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};
	const loadEventCouponFunction = options.loadEventCouponFunction;
	const loadData = useCallback(async () => {
		setIsLoading(true);
		try {
			const coupon = await loadEventCouponFunction();
			setCoupon(coupon);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		setIsLoading(false);
	}, [loadEventCouponFunction]);


	const isAuthenticated = useIsAuthenticated();
	useEffect(() => {
		if (isAuthenticated) {
			loadData();
		}
		return () => {};
	}, [isAuthenticated, loadData]);


	if (!isAuthenticated) {
		return <>
			<h3>Please sign in</h3>
			<div>
				To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
			</div>
		</>
	}

	const saveEventCoupon = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		setMessage("");

		let response: UpdateResponse<EventCoupon>;
		try {
			response = await options.saveEventCouponFunction(coupon as EventCoupon);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		if (response.item !== null) {
			setCoupon(response.item);
		}
		if (response.success) {
			if (options.isNewCoupon) {
				window.location.assign(`/admin/events/${slug}/coupons/${coupon?.id}`);
				return;
			}
			setMessage("Saved");
			setValidationErrors([]);
		} else {
			setMessage("Failed to save");
			console.log(response.validationErrors);
			setValidationErrors(response.validationErrors);
		}

		setIsLoading(false);
	}

	function isValid(propertyName: string): boolean {
		if (!validationErrors) {
			return true
		}
		return validationErrors.findIndex(e => e.propertyName === propertyName) < 0;
	}
	function validationErrorFor(propertyName: string) {
		if (!validationErrors) {
			return <></>
		}
		const propertyErrors = validationErrors.filter(e => e.propertyName === propertyName);
		if (propertyErrors.length === 0) {
			return <></>
		}
		return <>
			<div className="invalid-feedback" key={propertyName}>
				{validationErrors
					.filter(e => e.propertyName === propertyName)
					.map((e, i) => <div key={`${propertyName}-${i}`}>{e.errorMessage}</div>)}
			</div>
		</>
	}

	const updateStringProperty = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		let couponTemp = coupon as any;
		if (couponTemp === undefined) {
			return;
		}
		const target = e.target;
		const name = target.name;


		const newValue = e.target.value as string;

		couponTemp[name] = newValue
		setCoupon(couponTemp);
	}
	const updateCheckboxProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
		let couponTemp = coupon as any;
		if (couponTemp === undefined) {
			return;
		}
		const target = e.target;
		const name = target.name;

		const newValue = e.target.checked;

		couponTemp[name] = newValue
		setCoupon(couponTemp);
	}

	let eventCouponInfo = <></>
	if (coupon) {
		eventCouponInfo = <>
			<form onSubmit={async (e) => saveEventCoupon(e)}>
				<div className="form-group">
					<label className="control-label" htmlFor="id">Id</label>
					<small className="form-text text-muted">This is the coupon code that you give to customers</small>
					<input className={`form-control ${isValid("id") ? "" : "is-invalid"}`} disabled={!options.isNewCoupon} type="text" id="id" name="id" defaultValue={coupon.id} onChange={e => updateStringProperty(e)}></input>
					{validationErrorFor("id")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="title">Title</label>
					<small className="form-text text-muted">Title shown in the admin pages</small>
					<input className={`form-control ${isValid("title") ? "" : "is-invalid"}`} type="text" id="title" name="title" defaultValue={coupon.title} onChange={e => updateStringProperty(e)} ></input>
					{validationErrorFor("title")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="stripeCouponId">Stripe Coupon ID</label>
					<small className="form-text text-muted">The ID of the coupon in Stripe</small>
					<input className={`form-control ${isValid("stripeCouponId") ? "" : "is-invalid"}`} type="text" id="stripeCouponId" name="stripeCouponId" defaultValue={coupon.stripeCouponId} onChange={e => updateStringProperty(e)} ></input>
					{validationErrorFor("stripeCouponId")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="isActive">Is Active</label>
					<input className={`form-check-input ${isValid("isActive") ? "" : "is-invalid"}`} type="checkbox" id="isActive" name="isActive" defaultChecked={coupon.isActive} onChange={e => updateCheckboxProperty(e)}></input>
					<small className="form-text text-muted">A coupon must be marked as Active to be used</small>
					{validationErrorFor("isActive")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="validFrom">Valid From</label>
					<small className="form-text text-muted">If set then the coupon will only be usable after this date</small>
					<input className={`form-control ${isValid("validFrom") ? "" : "is-invalid"}`} type="datetime-local" id="validFrom" name="validFrom" defaultValue={coupon.validFrom} onChange={e => updateStringProperty(e)}></input>
					{validationErrorFor("validFrom")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="validUntil">Valid Until</label>
					<small className="form-text text-muted">If set then the coupon will only be usable before this date</small>
					<input className={`form-control ${isValid("validUntil") ? "" : "is-invalid"}`} type="datetime-local" id="validUntil" name="validUntil" defaultValue={coupon.validUntil} onChange={e => updateStringProperty(e)}></input>
					{validationErrorFor("validFrom")}
				</div>
				<div className="form-group">
					<input type="submit" value="Save" className="btn btn-primary" />
				</div>
				{options.isNewCoupon ?
					<></> :
					<div>
						<a href={`/admin/events/${slug}/coupons`}>Go to booking coupons</a>
					</div>}
			</form>
		</>
	}
	return <>
		<h1><a href="/admin">Admin</a> / <a href="/admin/events">Events</a> / <a href={`/admin/events/${slug}`}>{slug}</a> / <a href={`/admin/events/${slug}/coupons`}>Coupons</a> / {couponId} </h1>
		{isLoading ? "loading" : ""}
		{message ? <div className="alert alert-success" role="alert" key="message">{message}</div> : <></>}
		{error ? <div className="alert alert-danger" role="alert" key="error">{error}</div> : <></>}
		{eventCouponInfo}
	</>;
}