
import Button from "react-bootstrap/Button";
import { Config } from '../config'
import React, { Component } from "react";
import { getApiTestResult } from '../api'
import axios from 'axios';

// data loading patterns: https://nordschool.com/react-data-fetching/
export class ErrorTestContent extends Component {
  state = {
    // Initial state.
    isLoading: false,
    text: ""
  };

  render() {
    return (
      <>
        <div>No error handling:</div>
        <div>
          <Button disabled={this.state.loading} onClick={this.getCallErrorFunc(200)}>200</Button>
          <Button disabled={this.state.loading} onClick={this.getCallErrorFunc(400)}>400</Button>
          <Button disabled={this.state.loading} onClick={this.getCallErrorFunc(404)}>404</Button>
          <Button disabled={this.state.loading} onClick={this.getCallErrorFunc(500)}>500</Button>
        </div>
        <div>With error handling:</div>
        <div>
          <Button disabled={this.state.loading} onClick={this.getCallErrorFuncWithErrorHandling(200)}>200</Button>
          <Button disabled={this.state.loading} onClick={this.getCallErrorFuncWithErrorHandling(400)}>400</Button>
          <Button disabled={this.state.loading} onClick={this.getCallErrorFuncWithErrorHandling(404)}>404</Button>
          <Button disabled={this.state.loading} onClick={this.getCallErrorFuncWithErrorHandling(500)}>500</Button>
        </div>
        <div>{this.state.text}</div>
      </>
    );
  }

  componentDidMount() {
    this.getDataAsync();
  }

  getCallErrorFunc(statusCode) {
    return () => this.callErrorTest(statusCode);
  }
  getCallErrorFuncWithErrorHandling(statusCode) {
    return () => this.callErrorTestWithErrorHandling(statusCode);
  }

  async callErrorTestWithErrorHandling(statusCode) {
    this.setState({ ...this.state, isLoading: true, text: "calling..." });
    let response;
    try {
      response = await axios.get(`${Config.current().apiConfig.webApi}/utils/status/${statusCode}`, null, null);
      this.setState({ ...this.state, isLoading: false, text: JSON.stringify(response) });
    } catch (error) {
      console.log(error);
      console.log(error.response);
      this.setState({ ...this.state, isLoading: false, text: JSON.stringify({response, error}) });
    }
  }
  async callErrorTest(statusCode) {
    this.setState({ ...this.state, isLoading: true, text: "calling..." });

    const response = await axios.get(`${Config.current().apiConfig.webApi}/utils/status/${statusCode}`, null, null);

    this.setState({ ...this.state, isLoading: false, text: JSON.stringify(response) });
  }

  async getDataAsync() {
    this.setState({ ...this.state, isLoading: true });
    const result = await getApiTestResult();
    const name = result == null ? "<error>" : result.name;
    this.setState({
      ...this.state,
      isLoading: false,
      name: name
    });
    // TODO try/catch?
  }
}

