import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import React from "react";
import {Config} from "../config";

export default function Admin() {
	const {instance} = useMsal();
	const signIn = async (e: any) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};

	const isAuthenticated = useIsAuthenticated();
	if (!isAuthenticated) {
		return <>
			<h3>Please sign in</h3>
			<div>
				To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
			</div>
		</>
	}
	return <>
		<h1>Admin</h1>
		<div className="alert alert-info" role="alert">** Don't forget the new admin experience in the <a href="https://my.emilieleeks.com/admin">portal</a> **</div>
		<ul>
			<li><a href="/admin/events">Events</a></li>
			<li><a href="/admin/bookings">Bookings</a></li>
			<li><a href="/admin/customers">Customers</a></li>
			<li><a href="/admin/migrations">Database Migrations</a></li>
		</ul>
	</>;
}