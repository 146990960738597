import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import axios, {AxiosResponse} from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router";
import {getApiAuthHeaderValue} from "../api";
import FormCheckbox from "../Components/FormCheckbox";
import {Config} from "../config";
import {UpdateResponse, ValidationError} from "./Common";

enum EventOptionType {
	Subscription = "Subscription",
	OneOff = "OneOff"
}

class EventOption {
	id: string = "";
	title: string = "";
	type: EventOptionType | undefined;
	stripeId: string = "";
	numberOfPlacesUsed: number = 1;
	sortOrder: number = -1;
	isActive: boolean = true;
	validFrom?: string;
	validUntil?: string;
}

interface AdminEventOptionEditOptions {
	isNewOption: boolean
	loadEventOptionFunction: () => Promise<EventOption>,
	saveEventOptionFunction: (event: EventOption) => Promise<UpdateResponse<EventOption>>
}

export function AdminEventOptionEdit() {
	const {slug, optionId} = useParams() as {slug: string, optionId: string};
	async function loadEventOption(): Promise<EventOption> {
		const authHeaderValue = await getApiAuthHeaderValue();
		const response = await axios.get(
			`${Config.current().apiConfig.webApi}/eladmin/events/${slug}/options/${optionId}`, {
			headers: {
				"Authorization": authHeaderValue,
			}
		});
		const option = response.data.option as EventOption;
		console.log(option);
		return option;
	}
	async function saveEventOption(option: EventOption): Promise<UpdateResponse<EventOption>> {
		const authHeaderValue = await getApiAuthHeaderValue();
		const response: AxiosResponse = await axios.put(
			`${Config.current().apiConfig.webApi}/eladmin/events/${slug}/options/${optionId}`, option, {
			headers: {
				"Authorization": authHeaderValue,
			}
		});
		const updateResponse = response.data as UpdateResponse<EventOption>;
		return updateResponse;
	}
	return AdminEventOptionEditCore({
		isNewOption: false,
		loadEventOptionFunction: useCallback(loadEventOption, [slug, optionId]),
		saveEventOptionFunction: saveEventOption
	})
}
export function AdminEventOptionNew() {
	const {slug} = useParams() as {slug: string};
	async function loadEventOption(): Promise<EventOption> {
		const e = new EventOption();
		e.type = EventOptionType.Subscription;
		return e
	}
	async function saveEventOption(option: EventOption): Promise<UpdateResponse<EventOption>> {
		const authHeaderValue = await getApiAuthHeaderValue();
		const response: AxiosResponse = await axios.post(
			`${Config.current().apiConfig.webApi}/eladmin/events/${slug}/options`, option, {
			headers: {
				"Authorization": authHeaderValue,
			}
		});
		const updateResponse = response.data as UpdateResponse<EventOption>;
		return updateResponse;
	}
	return AdminEventOptionEditCore({
		isNewOption: true,
		loadEventOptionFunction: useCallback(loadEventOption, []),
		saveEventOptionFunction: saveEventOption
	})
}

function AdminEventOptionEditCore(options: AdminEventOptionEditOptions) {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [error, setError] = useState<any>();
	const [message, setMessage] = useState<string>();
	const [validationErrors, setValidationErrors] = useState<ValidationError[]>();
	const [option, setOption] = useState<EventOption>();

	const {slug, optionId} = useParams() as {slug: string, optionId: string};

	const {instance} = useMsal();
	const signIn = async (e: any) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};

	const loadEventOptionFunction = options.loadEventOptionFunction;
	const loadData = useCallback(async () => {
		setIsLoading(true);
		try {
			const option = await loadEventOptionFunction();
			setOption(option);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		setIsLoading(false);
	}, [loadEventOptionFunction]);
	const isAuthenticated = useIsAuthenticated();
	useEffect(() => {

		if (isAuthenticated) {
			loadData();
		}
		return () => {};
	}, [isAuthenticated, loadData]);


	if (!isAuthenticated) {
		return <>
			<h3>Please sign in</h3>
			<div>
				To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
			</div>
		</>
	}

	const saveEventOption = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		setMessage("");

		let response: UpdateResponse<EventOption>;
		try {
			response = await options.saveEventOptionFunction(option as EventOption);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		if (response.item !== null) {
			setOption(response.item);
		}
		if (response.success) {
			if (options.isNewOption) {
				window.location.assign(`/admin/events/${slug}/options/${option?.id}`);
				return;
			}
			setMessage("Saved");
			setValidationErrors([]);
		} else {
			setMessage("Failed to save");
			console.log(response.validationErrors);
			setValidationErrors(response.validationErrors);
		}

		setIsLoading(false);
	}

	function isValid(propertyName: string): boolean {
		if (!validationErrors) {
			return true
		}
		return validationErrors.findIndex(e => e.propertyName === propertyName) < 0;
	}
	function validationErrorFor(propertyName: string) {
		return rawValidationErrorFor(propertyName) ?? <></>
	}
	function rawValidationErrorFor(propertyName: string) {
		if (!validationErrors) {
			return undefined;
		}
		const propertyErrors = validationErrors.filter(e => e.propertyName === propertyName);
		if (propertyErrors.length === 0) {
			return undefined;
		}
		return <>
			<div className="invalid-feedback" key={propertyName}>
				{validationErrors
					.filter(e => e.propertyName === propertyName)
					.map((e, i) => <div key={`${propertyName}-${i}`}>{e.errorMessage}</div>)}
			</div>
		</>
	}

	const updateStringProperty = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		let optionTemp = option as any;
		if (optionTemp === undefined) {
			return;
		}
		const target = e.target;
		const name = target.name;


		const newValue = e.target.value as string;

		optionTemp[name] = newValue
		setOption(optionTemp);
	}
	const updateIntProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
		let optionTemp = option as any;
		if (optionTemp === undefined) {
			return;
		}
		const target = e.target;
		const name = target.name;

		const newValue = e.target.value as string;

		optionTemp[name] = newValue
		setOption(optionTemp);
	}

	let eventOptionInfo = <></>
	if (option) {
		eventOptionInfo = <>
			<form onSubmit={async (e) => saveEventOption(e)}>
				<div className="form-group">
					<label className="control-label" htmlFor="id">Id</label>
					<small className="form-text text-muted">The url for the booking is: .../events/&lt;event-slug&gt;/&lt;id&gt;</small>
					<input className={`form-control ${isValid("id") ? "" : "is-invalid"}`} disabled={!options.isNewOption} type="text" id="id" name="id" defaultValue={option.id} onChange={e => updateStringProperty(e)}></input>
					{validationErrorFor("id")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="title">Title</label>
					<small className="form-text text-muted">The title for the booking option (e.g. Single Person)</small>
					<input className={`form-control ${isValid("title") ? "" : "is-invalid"}`} type="text" id="title" name="title" defaultValue={option.title} onChange={e => updateStringProperty(e)} ></input>
					{validationErrorFor("title")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="type">Type</label>
					<select className="form-control" id="type" name="type" defaultValue={option.type} onChange={e => updateStringProperty(e)}>
						<option value={EventOptionType.Subscription}>Subscription</option>
						<option value={EventOptionType.OneOff}>One-off</option>
					</select>
					{validationErrorFor("type")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="stripeId">Stripe ID</label>
					<small className="form-text text-muted">The stripe ID for the SKU with the pricing information for this booking option</small>
					<input className={`form-control ${isValid("stripeId") ? "" : "is-invalid"}`} type="text" id="stripeId" name="stripeId" defaultValue={option.stripeId} onChange={e => updateStringProperty(e)} ></input>
					{validationErrorFor("stripeId")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="numberOfPlacesUsed">Number of Places Used</label>
					<small className="form-text text-muted">The number of places taken up by this booking option. Use 1 unless this is an option for a couple etc</small>
					<input className={`form-control ${isValid("numberOfPlacesUsed") ? "" : "is-invalid"}`} type="text" id="numberOfPlacesUsed" name="numberOfPlacesUsed" defaultValue={option.numberOfPlacesUsed} onChange={e => updateIntProperty(e)} ></input>
					{validationErrorFor("numberOfPlacesUsed")}
				</div>
				<div className="form-group">
					<label className="control-label" htmlFor="sortOrder">Sort Order</label>
					<small className="form-text text-muted">When there are multiple options displayed this controls the order they are displayed in. E.g. an item with Sort Order 1 is shown before 2</small>
					<input className={`form-control ${isValid("sortOrder") ? "" : "is-invalid"}`} type="text" id="sortOrder" name="sortOrder" defaultValue={option.sortOrder} onChange={e => updateIntProperty(e)}></input>
					{validationErrorFor("totalPlaces")}
				</div>
				<FormCheckbox
					id="isActive"
					labelText="Is Active"
					helpText="A booking option must be marked as Active to be displayed"
					value={option.isActive}
					setValue={v=> {const newOption = {...option, isActive:v}; setOption(newOption)}}
					validationError={rawValidationErrorFor("isActive")}/>
				<div className="form-group">
					<label className="control-label" htmlFor="validFrom">Valid From</label>
					<small className="form-text text-muted">If set then the booking option will only be displayed after this date</small>
					<input className={`form-control ${isValid("validFrom") ? "" : "is-invalid"}`} type="datetime-local" id="validFrom" name="validFrom" defaultValue={option.validFrom} onChange={e => updateStringProperty(e)}></input>
					{validationErrorFor("validFrom")}
				</div>
				<div className="form-group">

					<label className="control-label" htmlFor="validUntil">Valid Until</label>
					<small className="form-text text-muted">If set then the booking option will only be displayed before this date</small>
					<input className={`form-control ${isValid("validUntil") ? "" : "is-invalid"}`} type="datetime-local" id="validUntil" name="validUntil" defaultValue={option.validUntil} onChange={e => updateStringProperty(e)}></input>
					{validationErrorFor("validFrom")}
				</div>
				<div className="form-group">
					<input type="submit" value="Save" className="btn btn-primary" />
				</div>
				{options.isNewOption ?
					<></> :
					<div>
						<a href={`/admin/events/${slug}/options`}>Go to booking options</a>
					</div>}
			</form>
		</>
	}
	return <>
		<h1><a href="/admin">Admin</a> / <a href="/admin/events">Events</a> / <a href={`/admin/events/${slug}`}>{slug}</a> / <a href={`/admin/events/${slug}/options`}>Options</a> / {optionId} </h1>
		{isLoading ? "loading" : ""}
		{message ? <div className="alert alert-success" role="alert" key="message">{message}</div> : <></>}
		{error ? <div className="alert alert-danger" role="alert" key="error">{error}</div> : <></>}
		{eventOptionInfo}
	</>;
}