import React, { Fragment } from "react";
import { Component } from "react";
import axios from 'axios';
import queryString from 'query-string'
import { Config } from '../config'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { getApiAuthHeaderValue } from '../api';


export const ChangeEmailConfirm = (props) => {
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const signIn = async (e) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};

	return (
		<>
			<AuthenticatedTemplate>
				<InjectedChangeEmailConfirm msal={instance} isAuthenticated={isAuthenticated} {...props} />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<h3>Please sign in</h3>
				<div>
					To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
				</div>
			</UnauthenticatedTemplate>
		</>
	);
};
class InjectedChangeEmailConfirm extends Component {
	state = {
		isLoading: false,
		isCompleted: false,
		token: null,
		newEmail: null,
		error: null,
	};

	render() {
		if (this.state.error) {
			return (
				<div className="row">
					<div className="col-md-12">
						<h3>Error</h3>
						<div>{this.state.error}</div>
					</div>
				</div>
			)
		}
		if (this.state.isCompleted) {
			return (
				<div className="row">
					<div className="col-md-12">
						<h3>Success</h3>
						<p>Your email address has been changed to {this.state.newEmail} and this email address will be used for future communications.</p>
						<p>Return to <a href="/my-account">Your Account</a></p>
					</div>
				</div>
			)
		}
		let changeEmailContent;
		if (this.state.newEmail) {
			changeEmailContent = (
				<>
					<h3>Confirm email address change</h3>
					<p>To confirm your change of email address to {this.state.newEmail} <button className="btn btn-primary" disabled={this.state.isLoading} onClick={(e) => this.confirmEmailChange(e)}>click here</button></p>
				</>
			)
		}
		return (
			<div className="row">
				<div className="col-md-12">
					{this.state.isLoading && (<p>processing, please wait...</p>)}
					{changeEmailContent}
				</div>
			</div>
		)
	}
	componentDidMount() {
		this.getDataAsync();
	}

	async getDataAsync() {
		const queryValues = queryString.parse(this.props.location.search);
		const { isAuthenticated } = this.props;
		console.log(`isAuthenticated: ${isAuthenticated}`);
		console.log(this);
		if (!isAuthenticated) {
			return;
		}
		this.setState({ ...this.state, isLoading: true, token: queryValues.token });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/my-account/customer/email-tokens/${queryValues.token}`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message
			});
			return;
		}
		console.log(response);
		const newEmail = response.data.newEmail;
		const error = response.data.error;


		this.setState({
			...this.state,
			isLoading: false,
			newEmail,
			error
		});
	}

	async confirmEmailChange(event) {
		// Block native form submission.
		event.preventDefault();

		this.setState({ ...this.state, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.post(
				`${Config.current().apiConfig.webApi}/my-account/customer/email`, {
				emailToken: this.state.token
			}, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message
				,
			});
			return;
		}
		console.log(response);

		this.setState({
			...this.state,
			error: null,
			isLoading: false,
			isCompleted: true,
		});
	}

}
