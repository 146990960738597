
import React, { Component } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { getApiTestResult } from '../api'

// data loading patterns: https://nordschool.com/react-data-fetching/
export class AuthTestContent extends Component {
  state = {
    // Initial state.
    isLoading: false,
    // name: ""
    claims: []
  };

  render() {
    return (
      <div className="App">
        <AuthenticatedTemplate>
          Signed in!!!
            <p>{this.state.isLoading ? "loading..." : ""}</p>
          {/* <p>{this.state.name ? this.state.name : ""}</p> */}
          <ul>
            {this.state.claims.map(c => (
              <li>{c.type}: {c.value}</li>
            ))}
          </ul>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <h5 className="card-title">Please sign-in to see your profile information.</h5>
        </UnauthenticatedTemplate>
      </div>
    );
  }

  componentDidMount() {
    this.getDataAsync();
  }

  async getDataAsync() {
    this.setState({ ...this.state, isLoading: true });
    const result = await getApiTestResult();
    const claims = result.claims == null ? [] : result.claims;
    this.setState({
      ...this.state,
      isLoading: false,
      // name: name
      claims: claims
    });
    // TODO try/catch?
  }
}

