import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {getApiAuthHeaderValue} from "../api";
import {Config} from "../config";

class Customer {
	id: number = -1;
	name: string = "";
	email: string = "";
	stripeId: string = "";
	stripeCustomerUrl: string = "";
}

export default function AdminCustomerList() {
	const [isLoading, setIsLoading] = useState<boolean>();
	const [error, setError] = useState<any>();
	const [customers, setCustomers] = useState<Customer[]>();

	const {instance} = useMsal();
	const signIn = async (e: any) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};
	const isAuthenticated = useIsAuthenticated();
	useEffect(() => {
		if (isAuthenticated)
			loadData();
		return () => {};
	}, [isAuthenticated]);


	if (!isAuthenticated) {
		return <>
			<h3>Please sign in</h3>
			<div>
				To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
			</div>
		</>
	}

	const loadData = async () => {
		setIsLoading(true);
		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/eladmin/customers`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setError(error?.response?.data?.error ?? error.message);
			return;
		}
		const customers = response.data.customers as Customer[];
		setCustomers(customers);
		setIsLoading(false);
	};

	if (error) {
		return <>
			<h1><a href="/admin">Admin</a> / Customers </h1>
			{error}
		</>
	}
	let customerList = <></>
	if (customers) {
		customerList = <>
			<table className="table">
				<thead>
					<tr>
						<th>Name</th>
						<th>Email</th>
						<th>Stripe Id</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{customers.map(c => <tr>
						<td><a href={`/admin/customers/${c.id}`}>{c.name}</a></td>
						<td>{c.email}</td>
						<td><a href={c.stripeCustomerUrl}>{c.stripeId}</a></td>
						<td><a href={`/admin/bookings?customerId=${c.id}`}>bookings...</a></td>
					</tr>)}
				</tbody>
			</table>
		</>
	}
	return <>
		<h1><a href="/admin">Admin</a> / Customers</h1>
		{isLoading ? "loading" : ""}
		{customerList}
	</>;
}