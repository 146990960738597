import React, { Fragment } from "react";
import { Component } from "react";
import axios from 'axios';
import { Config } from '../config'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { getApiAuthHeaderValue } from '../api';


export const SavedCards = (props) => {
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const signIn = async (e) => {
		e.preventDefault();
		await instance.loginRedirect(Config.current().authConfig.loginRequest);
	};

	return (
		<>
			<AuthenticatedTemplate>
				<InjectedSavedCards msal={instance} isAuthenticated={isAuthenticated} {...props} />
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<h3>Please sign in</h3>
				<div>
					To access this section you need to <button type="button" className="link-button" onClick={(e) => signIn(e)}>sign in or create an account</button>
				</div>
			</UnauthenticatedTemplate>
		</>
	);
};
class InjectedSavedCards extends Component {
	state = {
		isLoading: false,
		customer: null,
		error: null,
	};
	bookingId = this.props.match.params.bookingid;

	render() {
		if (this.state.error !== null) {
			return (
				<div className="row">
					<div className="col-md-12">
						<h3>Error</h3>
						<div>{this.state.error}</div>
					</div>
				</div>
			)
		}
		let cardsContent;
		if (this.state.customer) {
			const customer = this.state.customer;
			if (customer.cards.length > 0) {
				cardsContent = (
					<>
						<div className="row">
							<div className="col-md-12">
								<h3>Saved Cards</h3>
							</div>
						</div>
						<div className="row">
							{customer.cards.map(c => (
								<div className="col-md-3" key={c.id}>
									<div className="saved-card-container">
										<div className="saved-card">
											<form onSubmit={(e) => this.handleDeleteSavedCard(e, c)}>
												<p><span className={`card-image card-image-${c.brand}`}></span>{c.brand} card ****{c.last4}</p>
												<p>Expires {c.expiryMonth} / {c.expiryYear}</p>
												<button type="submit" className="btn btn-danger" disabled={this.state.isLoading}>
													<span>
														{this.state.isLoading ? (
															<div className="spinner" id="spinner"></div>
														) : (
															"Delete this card"
														)}
													</span>
												</button>
											</form>
										</div>
									</div>
								</div>
							))}
						</div>
					</>)
			} else {
				cardsContent = (
					<>
						<div className="row">
							<div className="col-md-12">
								<h3>Saved Cards</h3>
								<p>You have no saved cards</p>
							</div>
						</div>
					</>)
			}
		}
		return (
			<>
				<div className="row">
					<div className="col-md-12">
					{this.state.isLoading ? <div>Processing, please wait...<span className="spinner" id="spinner"></span></div> : <></>}
					</div>
				</div>
				{ cardsContent}
			</>
		)
	}
	componentDidMount() {
		this.getDataAsync();
	}

	async getDataAsync() {
		const { isAuthenticated } = this.props;
		console.log(`isAuthenticated: ${isAuthenticated}`);
		console.log(this);
		if (!isAuthenticated) {
			return;
		}
		this.setState({ ...this.state, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.get(
				`${Config.current().apiConfig.webApi}/my-account/customer`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message
			});
			return;
		}
		console.log(response);
		const customer = response.data;


		this.setState({
			...this.state,
			error: null, // reset so that the retryPayment call clears it on success
			isLoading: false,
			customer
		});
	}

	async handleDeleteSavedCard(event, card) {
		// Block native form submission.
		event.preventDefault();

		console.log("delete card");
		console.log(card);

		const { isAuthenticated } = this.props;
		if (!isAuthenticated) {
			return;
		}
		this.setState({ ...this.state, isLoading: true });

		const authHeaderValue = await getApiAuthHeaderValue();
		let response;
		try {
			response = await axios.delete(
				`${Config.current().apiConfig.webApi}/my-account/customer/cards/${card.id}`, {
				headers: {
					"Authorization": authHeaderValue,
				}
			});
		} catch (error) {
			console.log(error);
			this.setState({
				...this.state,
				isLoading: false,
				error: error?.response?.data?.error ?? error.message
			});
			return;
		}
		console.log(response);

		const customer = this.state.customer;
		var newCards = [];
		for (let i = 0; i < customer.cards.length; i++) {
			const c = customer.cards[i];
			if (c !== card) {
				newCards.push(c);
			}
		}
		customer.cards = newCards

		this.setState({
			...this.state,
			error: null, // reset so that the retryPayment call clears it on success
			isLoading: false,
			customer,
		});
	}
}
